// tempexp_clickme-vacancy-of-the-day-experiment_file
import type { AxiosRequestHeaders } from 'axios';

import Cookies from 'bloko/common/Cookies';

import type { VacanciesOfTheDay } from 'src/models/vacanciesOfTheDay';
import { fetcher } from 'src/utils/fetcher';

import { EMPTY_ARRAY, MAX_VOD_VACANCIES_COUNT } from 'src/components/RainbowCatalog/const';
import type { ClickmeVacancy, Vacancies } from 'src/components/RainbowCatalog/types';

interface RequestData {
    banners: ClickmeVacancy[];
}

function getExtraHeaders() {
    const hhuid = Cookies.get('hhuid');
    const hhid = Cookies.get('_hi');

    const headers = {
        Hhuid: hhuid,
        Token: hhuid,
        Hhid: null as string | null,
    };

    if (hhid) {
        headers.Hhid = hhid;
    }

    return headers;
}

function isVacanciesOfTheDay(d: unknown): d is RequestData {
    return Boolean(
        d && d instanceof Object && Array.isArray((d as RequestData).banners) && (d as RequestData).banners.length
    );
}

function isRealVacancyOfTheDay(vacancyOfTheDayId: number | null = null) {
    return vacancyOfTheDayId !== null;
}

function filterClickmeVacancies(vacancyOfTheDay: Vacancies, clickmeVacancies: ClickmeVacancy[]) {
    const vodVacanciesIds = new Set(
        vacancyOfTheDay.reduce<number[]>((result, { vacancyId, vacancyOfTheDayId }) => {
            if (isRealVacancyOfTheDay(vacancyOfTheDayId)) {
                result.push(vacancyId);
            }

            return result;
        }, [])
    );

    return clickmeVacancies.filter((clickmeVacancy) => {
        if ('empty' in clickmeVacancy || 'type' in clickmeVacancy) {
            return false;
        }

        return !vodVacanciesIds.has(clickmeVacancy.vacancyId);
    }) as Vacancies;
}

function isClickmeVacanciesShuffleEnabled(VODShufflingValue: number): boolean {
    if (!VODShufflingValue) {
        return false;
    }

    return Boolean(Math.random() * 100 >= 100 - VODShufflingValue);
}

function computeBannersIds(bannersIds?: string[], vacanciesCount = MAX_VOD_VACANCIES_COUNT) {
    if (!bannersIds || bannersIds.length === 0) {
        const startBannerPlace = 700;
        const result = [];

        for (let i = 0; i <= vacanciesCount; i++) {
            result.push(startBannerPlace + i);
        }

        return result;
    }

    return bannersIds.slice(0, vacanciesCount);
}

function getResultVacancies(vacancyOfTheDay: Vacancies, clickmeResponse: unknown) {
    return isVacanciesOfTheDay(clickmeResponse)
        ? filterClickmeVacancies(vacancyOfTheDay, clickmeResponse.banners)
        : EMPTY_ARRAY;
}

function reassignCodeValues(vacancies: Vacancies) {
    vacancies.forEach((vacancy) => {
        if (vacancy.company.department) {
            vacancy.company.department['@code'] = vacancy.company.department.code;
        }
    });

    return vacancies;
}

function translateAreas(vacancies: Vacancies) {
    const vacanciesToTranslate = vacancies.reduce<Set<number>>((result, { area }) => {
        if (!area.name) {
            result.add(area.id);
        }

        return result;
    }, new Set());

    if (!vacanciesToTranslate.size) {
        return Promise.resolve(vacancies);
    }

    return fetcher
        .get('/shards/areas_translate', { params: { areas: [...vacanciesToTranslate.values()] } })
        .then((result) => {
            vacancies.forEach((vacancy) => {
                if (!vacancy.area.name && result[vacancy.area.id]) {
                    vacancy.area.name = result[vacancy.area.id];
                }
            });

            return vacancies;
        })
        .catch(() => vacancies);
}

const getClickmeVacancies = (
    vacancyOfTheDay: Pick<VacanciesOfTheDay, 'vacancies' | 'compensation' | 'clickmeVacanciesPlacesList'>,
    VODShufflingValue: number,
    vacanciesCount = MAX_VOD_VACANCIES_COUNT
): Promise<Vacancies> => {
    const url = window.globalVars.bannersBatchUrl;

    if (!url || !isClickmeVacanciesShuffleEnabled(VODShufflingValue)) {
        return Promise.resolve(EMPTY_ARRAY);
    }

    return fetcher
        .get(url, {
            params: {
                place: computeBannersIds(vacancyOfTheDay.clickmeVacanciesPlacesList, vacanciesCount),
                format: 'json',
                vod: true,
            },
            headers: getExtraHeaders() as AxiosRequestHeaders,
        })
        .then((vacancies) =>
            translateAreas(reassignCodeValues(getResultVacancies(vacancyOfTheDay.vacancies, vacancies)))
        );
};

export default getClickmeVacancies;
