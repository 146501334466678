import { Link, Text } from '@hh.ru/magritte-ui';
import { TalantixBrandSize24, TalantixGrayscaleSize24 } from '@hh.ru/magritte-ui/service';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ServiceCard from 'src/components/EmployerServicesWidget/ServiceCard';
import { useHandleAnalyticsCardClick } from 'src/components/EmployerServicesWidget/useHandleAnalyticsCardClick';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import {
    UTM_CAMPAIGN_MAIN_WIDGET_CONNECTED_SERVICE,
    UTM_CAMPAIGN_MAIN_WIDGET_UNCONNECTED_SERVICE,
    useTalantixPromoUrl,
} from 'src/hooks/useTalantixPromoUrl';
import { EmployerServiceWidget } from 'src/models/employerServicesWidget';

const TrlKeys = {
    title: 'employer.index.servicesWidget.talantix.title',
    connectTitle: 'employer.index.servicesWidget.talantix.connect.title',
    connect: 'employer.index.servicesWidget.talantix.connect',
    talantixTariffDaysLeftZero: 'supernova.billingInfo.talantixTariffDaysLeft.zero',
    days: {
        one: 'employer.index.servicesWidget.talantix.days.one',
        some: 'employer.index.servicesWidget.talantix.days.some',
        many: 'employer.index.servicesWidget.talantix.days.many',
    },
};

const TalantixServiceCard: TranslatedComponent<EmployerServiceWidget> = ({ trls, active, name }) => {
    const talantixDaysLeft = useSelector(
        ({ employerIndexPage }) => employerIndexPage?.oldEmployer?.talantixTariffDaysLeft
    );
    const talantixPromoUrl = useTalantixPromoUrl(
        active ? UTM_CAMPAIGN_MAIN_WIDGET_CONNECTED_SERVICE : UTM_CAMPAIGN_MAIN_WIDGET_UNCONNECTED_SERVICE
    );

    const handleClick = useHandleAnalyticsCardClick({ name, active });

    const card = (
        <ServiceCard
            name={name}
            isActive={active}
            title={trls[active ? TrlKeys.title : TrlKeys.connectTitle]}
            icon={active ? <TalantixBrandSize24 /> : <TalantixGrayscaleSize24 />}
        >
            {active ? (
                <Text typography="subtitle-1-semibold">
                    {talantixDaysLeft === 0 && trls[TrlKeys.talantixTariffDaysLeftZero]}
                    {talantixDaysLeft && talantixDaysLeft > 0 ? (
                        <ConversionNumber
                            hasValue
                            one={trls[TrlKeys.days.one]}
                            some={trls[TrlKeys.days.many]}
                            many={trls[TrlKeys.days.some]}
                            value={talantixDaysLeft}
                        />
                    ) : null}
                </Text>
            ) : (
                <Link target="_blank" onClick={handleClick} href={talantixPromoUrl} typography="label-2-regular">
                    {trls[TrlKeys.connect]}
                </Link>
            )}
        </ServiceCard>
    );

    return active ? (
        <SPALink target="_blank" onClick={handleClick} to={talantixPromoUrl}>
            {card}
        </SPALink>
    ) : (
        card
    );
};

export default translation(TalantixServiceCard);
