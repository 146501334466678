import { useSelector } from 'src/hooks/useSelector';
import { additionalPurchaseCodes, ServiceCode } from 'src/models/price/productCodes';

const COULD_BE_WITH_CIV = [ServiceCode.Disposable, ...additionalPurchaseCodes];
const RESUME_ACCESS_CODES = [
    ServiceCode.ContactsViewWithStandart,
    ServiceCode.FullAccess,
    ServiceCode.FullAccessWithStandart,
    ServiceCode.Bundle,
];

const useGetPublicationItems = () => {
    const employerIndexPage = useSelector(({ employerIndexPage }) => employerIndexPage);

    if (employerIndexPage.oldEmployer) {
        const { serviceDescription } = employerIndexPage.oldEmployer.employer;

        return serviceDescription.filter(
            (item) =>
                RESUME_ACCESS_CODES.includes(item.code) ||
                (COULD_BE_WITH_CIV.includes(item.code) &&
                    typeof item.civBalance === 'number' &&
                    item.civBalance >= 0 &&
                    item.temporalCiv)
        );
    }

    return [];
};

export { useGetPublicationItems };
