import { ReactNode, useState } from 'react';
import classnames from 'classnames';

import { Button, Card } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import BannerCard from 'src/components/EmployerBannersWidget/BannerCard';
import StaticImg from 'src/components/StaticImg';
import translation from 'src/components/translation';
import { EmployerBannerName } from 'src/models/employerBannersWidget';

import styles from './styles.less';

interface FlippableBannerQrCodeProps {
    onClick: () => void;
    name: EmployerBannerName;
    children: ReactNode;
    qrCodeImgPath: string;
    bannerHeight?: number;
}

const TrlKeys = {
    button: 'employer.index.bannersWidget.mobileApp.button',
    closeButton: 'employer.index.bannersWidget.mobileApp.closeButton',
    qrCodeAltText: 'employer.banner.suitable-gifts-promo.qr-code.alt-text',
};

const FlippableBannerQrCode: TranslatedComponent<FlippableBannerQrCodeProps> = ({
    trls,
    onClick,
    name,
    children,
    qrCodeImgPath,
    bannerHeight,
}) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleToggleFlipped = () => setIsFlipped((prev) => !prev);

    const handleClick = () => {
        onClick();
        handleToggleFlipped();
    };

    return (
        <BannerCard
            style="secondary"
            bannerHeight={bannerHeight}
            button={
                <Button size="small" mode="primary" style="contrast" stretched onClick={handleClick}>
                    {isFlipped ? trls[TrlKeys.closeButton] : trls[TrlKeys.button]}
                </Button>
            }
            name={name}
        >
            <div
                data-qa="card-flipper-content"
                className={classnames(styles.cardFlipper, { [styles.cardFlipperFlipped]: isFlipped })}
            >
                <div className={styles.cardFront}>{children}</div>
                <div className={styles.cardBack}>
                    <div className={styles.cardBackContent}>
                        <Card padding={8} style="primary" borderRadius={12}>
                            <div data-qa="card-back-content" className={styles.qrCodeContainer}>
                                <StaticImg
                                    className={styles.qrCode}
                                    path={qrCodeImgPath}
                                    alt={trls[TrlKeys.qrCodeAltText]}
                                />
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </BannerCard>
    );
};

export default translation(FlippableBannerQrCode);
