import { FC, PropsWithChildren, Fragment, useState } from 'react';

import advertisingBannerClick from '@hh.ru/analytics-js-events/build/xhh/common/main/advertising_banner_click';
import {
    GridColumn,
    GridLayout,
    GridRow,
    useBreakpoint,
    VSpacingContainer,
    VSpacing as VSpacingMagritte,
} from '@hh.ru/magritte-ui';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AppBanner from 'src/components/AppBanner';
import AdsExternalBanner from 'src/components/Banner/AdsExternalBanner';
import FooterMobileBanner from 'src/components/Footer/MobileBanner';
import InternalPromoBlock from 'src/components/InternalPromoBlock';
import NewsBox from 'src/components/NewsBox';
import RainbowCatalog from 'src/components/RainbowCatalog';
import { useFetchVacanciesOfTheDay } from 'src/components/RainbowCatalog/useFetchVacanciesOfTheDay';
import RecommendedVacanciesMobile from 'src/components/RecommendedVacancies/Mobile';
import MainPageSignUpModal from 'src/components/SignupModal/MainPageSignUpModal';
import StayInTouch from 'src/components/StayInTouch';
import UsefulLinks from 'src/components/UsefulLinks';
import VacanciesOfTheDay from 'src/components/VacanciesOfTheDay';
import VacanciesOfTheDayBanners from 'src/components/VacanciesOfTheDay/Banners';
import VacanciesOfTheDayMobile from 'src/components/VacanciesOfTheDay/Mobile';
import { MIN_VACANCIES_NUM_TO_SHOW } from 'src/components/VacanciesOfTheDay/constants';
import WorkInCompany from 'src/components/WorkInCompany';
import WorkInCompanyMobile from 'src/components/WorkInCompany/Mobile';
import WorkInOtherCities from 'src/components/WorkInOtherCities';
import WorkInProfession from 'src/components/WorkInProfession';
import WorkInProfessionMobile from 'src/components/WorkInProfession/Mobile';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsRabotaBy } from 'src/hooks/useSites';
import { BannerObject, BannerPlace } from 'src/models/banners';
import { UserType } from 'src/models/userType';

import AppPromoMobile from 'src/components/IndexPageAnonymous/AppPromoMobile';
import AppSmartBanner from 'src/components/IndexPageAnonymous/AppSmartBanner';
import CreateResumeButton from 'src/components/IndexPageAnonymous/CreateResumeButton';
import Dashboard from 'src/components/IndexPageAnonymous/Dashboard';
import DashboardMobileSearch from 'src/components/IndexPageAnonymous/DashboardMobileSearch';
import PresenceGoalsB from 'src/components/IndexPageAnonymous/PresenceGoals/PresenceGoalsB';
import PresenceGoalsC from 'src/components/IndexPageAnonymous/PresenceGoals/PresenceGoalsC';
import SignUpBanner from 'src/components/IndexPageAnonymous/SignUp/Banner';
import SuitableVacancies from 'src/components/IndexPageAnonymous/SuitableVacancies';
import VacanciesOfTheDayMagritte from 'src/components/IndexPageAnonymous/VacanciesOfTheDay';
import WorkInCity from 'src/components/IndexPageAnonymous/WorkInCity/WorkInCity';

import styles from './styles.less';

const TrlKeys = {
    newsTitle: 'index.newsTitle',
    articlesTitle: 'index.Articles',
    usefulTitle: 'index.headers.useful',
    filterTitle: 'vacancySearch.filterTitle',
    popularProfessionsTiltle: 'index.professions.mainTitle.anonymous',
    feedTitle: {
        all: 'index.anonymous.vacancies.title',
        withFilter: 'index.anonymous.vacancies.title.with.filter',
    },
    headerPhone: 'signup.header.phone',
    signup: {
        titleA: 'index.anonymous.signup.title.a',
        titleB: 'index.anonymous.signup.title.b',
        subtitle: 'index.anonymous.signup.subtitle',
    },
};

const IndexAnonymous: TranslatedComponent<FC<PropsWithChildren>> = ({ trls }) => {
    const vacanciesOfTheDay = useSelector((state) => state.vacanciesOfTheDay);
    const stayInTouch = useSelector((state) => state.stayInTouch);
    const news = useSelector((state) => state.applicantNews);
    const articles = useSelector((state) => state.applicantArticles);
    const useful = useSelector((state) => state.applicantUseful);
    const bannersDashboardMobile = useSelector((state) => state.banners[BannerPlace.INDEX_DASHBOARD_MOBILE]);
    const bannersUnderRainbowMobile = useSelector((state) => state.banners[BannerPlace.INDEX_UNDER_RAINBOW_MOBILE]);
    const bannersHorizontal = useSelector((state) => state.banners[BannerPlace.INDEX_HORIZONTAL]);
    const bannersUnderNewsBoxMobile = useSelector((state) => state.banners[BannerPlace.INDEX_UNDER_NEWS_BOX_MOBILE]);
    // tempexp_32927_start
    const bannersDashboardMobileMagritte = useSelector(
        (state) => state.banners[BannerPlace.INDEX_DASHBOARD_MOBILE_MAGRITTE_ANONYMOUS]
    );
    const bannersUnderRainbowMobileMagritte = useSelector(
        (state) => state.banners[BannerPlace.INDEX_UNDER_RAINBOW_MOBILE_MAGRITTE_ANONYMOUS]
    );
    const bannersUnderNewsBoxMobileMagritte = useSelector(
        (state) => state.banners[BannerPlace.INDEX_UNDER_NEWS_BOX_MOBILE_MAGRITTE_ANONYMOUS]
    );
    const bannersUnderCreateResumeButton = useSelector((state) => state.banners[BannerPlace.INDEX_BOTTOM_MOBILE]);
    const bannersUnderCreateResumeButtonMagritte = useSelector(
        (state) => state.banners[BannerPlace.INDEX_BOTTOM_MOBILE_MAGRITTE_ANONYMOUS]
    );
    // tempexp_32927_end

    const showWicAndVod = vacanciesOfTheDay.success && vacanciesOfTheDay.vacancies.length > MIN_VACANCIES_NUM_TO_SHOW;
    const chatBot = useSelector((state) => state.chatBot);
    const professionalRoleCategoryCollection = useSelector((state) => state.professionalRoleCategoryCollection);
    const isZp = useIsZarplataPlatform();
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);
    const isPresenceGoalsExpB = useSelector((state) => state.isPresenceGoalsExpB);
    const isPresenceGoalsExpC = useSelector((state) => state.isPresenceGoalsExpC);

    const [isPresenceGoalsVisible, setPresenceGoalsVisible] = useState(isPresenceGoalsExpB && !isPresenceGoalsExpC);

    const { isMobile, isXS, isGtM } = useBreakpoint();

    useFetchVacanciesOfTheDay();

    const isRabotaBy = useIsRabotaBy();
    const siteNewsHref = isRabotaBy ? '/articles/applicants' : '/articles/site-news';

    const handleBannerClick = (type: string) => {
        advertisingBannerClick({ type });
    };

    if (isAnonymousMagritteExp) {
        return (
            <>
                <Dashboard />
                <DashboardMobileSearch />
                <VSpacingMagritte default={72} xs={0} s={24} m={72} l={72} />
                <GridLayout>
                    <AppSmartBanner spacingBottom={<VSpacingMagritte default={24} gteS={32} gteM={72} />} />
                    {isPresenceGoalsExpC && (
                        <>
                            <PresenceGoalsC />
                            <VSpacingMagritte default={72} xs={24} s={24} m={72} l={72} />
                        </>
                    )}
                    {!isPresenceGoalsVisible && <SignUpBanner />}
                    {isPresenceGoalsVisible && <PresenceGoalsB onSubmit={() => setPresenceGoalsVisible(false)} />}

                    <VSpacingMagritte default={72} xs={24} s={24} m={72} l={72} />
                    <RainbowCatalog />
                    <VSpacingMagritte default={72} xs={24} s={24} m={72} l={72} />

                    {isXS &&
                        bannersDashboardMobileMagritte?.map((banner: BannerObject) => (
                            <GridColumn xs={4} s={0} key={banner.id}>
                                <div onClick={() => handleBannerClick('XsBeforeCompanies')}>
                                    <div className={styles.bannerCard}>
                                        <AdsExternalBanner {...banner} />
                                    </div>
                                    <VSpacingMagritte default={72} xs={24} s={24} m={72} l={72} />
                                </div>
                            </GridColumn>
                        ))}
                    <VacanciesOfTheDayMagritte />
                    <VSpacingMagritte default={72} xs={24} s={24} m={72} l={72} />
                    {isXS &&
                        bannersUnderRainbowMobileMagritte?.map((banner: BannerObject) => (
                            <GridColumn xs={4} s={0} key={banner.id}>
                                <div onClick={() => handleBannerClick('XSAfterRainbow')}>
                                    <div className={styles.bannerCard}>
                                        <AdsExternalBanner {...banner} />
                                    </div>
                                </div>
                                <VSpacingMagritte default={72} xs={24} s={24} m={72} l={72} />
                            </GridColumn>
                        ))}

                    <WorkInCompany spacingBottom={<VSpacingMagritte default={32} xs={24} gteS={32} gteM={72} />} />
                    {isXS && (
                        <>
                            <SuitableVacancies />
                            <VSpacingMagritte default={72} xs={24} s={24} m={72} l={72} />
                        </>
                    )}

                    {isMobile && !isZp && <AppPromoMobile />}

                    {!isXS && (
                        <>
                            {bannersHorizontal?.map((banner: BannerObject) => (
                                <GridColumn xs={0} s={8} m={12} l={12} key={banner.id}>
                                    <div className={styles.bannerCard}>
                                        <AdsExternalBanner {...banner} />
                                    </div>
                                    <VSpacingMagritte default={72} xs={24} s={24} m={72} l={72} />
                                </GridColumn>
                            ))}
                        </>
                    )}

                    <WorkInProfession />
                    <VSpacingMagritte default={72} xs={24} s={24} m={72} l={72} />

                    <GridRow>
                        {isMobile && (
                            <GridColumn xs={4} s={8} m={6} l={4} flexibleContent>
                                {!!chatBot && <InternalPromoBlock />}
                                <VSpacingMagritte default={24} gteS={32} gteM={72} />
                            </GridColumn>
                        )}
                        <GridColumn xs={4} s={8} m={6} l={4} flexibleContent>
                            <NewsBox
                                items={news}
                                title={trls[TrlKeys.newsTitle]}
                                href={siteNewsHref}
                                isEmployerContext={false}
                                gapTop
                            />
                            <VSpacingMagritte default={24} gteS={32} gteM={72} />
                        </GridColumn>
                        <GridColumn xs={4} s={0}>
                            <VSpacingContainer default={24}>
                                {bannersUnderNewsBoxMobileMagritte?.map((banner: BannerObject) => (
                                    <div
                                        onClick={() => handleBannerClick('XSAfterNews')}
                                        key={banner.id}
                                        className={styles.bannerCard}
                                    >
                                        <AdsExternalBanner {...banner} />
                                    </div>
                                ))}
                            </VSpacingContainer>
                            <VSpacingMagritte default={24} gteS={32} />
                        </GridColumn>
                        <GridColumn xs={4} s={8} m={6} l={4} flexibleContent>
                            <NewsBox
                                items={articles}
                                title={trls[TrlKeys.articlesTitle]}
                                href="/articles"
                                isArticle
                                isEmployerContext={false}
                                gapTop
                            />
                            <VSpacingMagritte default={24} gteS={32} gteM={72} />
                        </GridColumn>
                        {!isMobile && (
                            <GridColumn xs={4} s={8} m={6} l={4} flexibleContent>
                                {!!chatBot && <InternalPromoBlock />}
                                <VSpacingMagritte default={24} gteS={32} gteM={72} />
                            </GridColumn>
                        )}
                        {isGtM && (
                            <GridColumn xs={4} s={8} m={12} l={8} flexibleContent>
                                <WorkInOtherCities />
                                <VSpacingMagritte default={24} gteS={32} gteM={72} />
                            </GridColumn>
                        )}

                        {!isMobile && (
                            <GridColumn xs={4} s={8} m={6} l={4} flexibleContent>
                                <UsefulLinks title={trls[TrlKeys.popularProfessionsTiltle]} items={useful} />
                                <VSpacingMagritte default={32} gteS={32} gteM={72} />
                            </GridColumn>
                        )}
                        {!isGtM && (
                            <GridColumn xs={4} s={8} m={12} l={8} flexibleContent>
                                <WorkInOtherCities />
                                <VSpacingMagritte default={24} gteS={32} gteM={72} />
                            </GridColumn>
                        )}
                        <GridColumn xs={4} s={0}>
                            <VSpacingContainer default={24}>
                                {bannersUnderCreateResumeButtonMagritte?.map((banner: BannerObject) => (
                                    <div
                                        onClick={() => handleBannerClick('XSBottom')}
                                        key={banner.id}
                                        className={styles.bannerCard}
                                    >
                                        <AdsExternalBanner {...banner} />
                                    </div>
                                ))}
                            </VSpacingContainer>
                            <VSpacingMagritte default={24} />
                        </GridColumn>
                    </GridRow>
                </GridLayout>

                {!isZp && (
                    <>
                        <FooterMobileBanner />
                        <VSpacingMagritte default={24} gteS={32} gteM={72} />
                    </>
                )}
                {!isXS && (
                    <GridLayout>
                        <GridRow>
                            <GridColumn xs={0} s={0} m={0} l={1} />
                            <GridColumn xs={0} s={8} m={12} l={10}>
                                <WorkInCity />
                                <VSpacingMagritte default={24} gteS={32} gteM={72} />
                            </GridColumn>
                            <GridColumn xs={0} s={0} m={0} l={1} />
                        </GridRow>
                    </GridLayout>
                )}
                <MainPageSignUpModal />
            </>
        );
    }

    return (
        <>
            <Dashboard />
            <DashboardMobileSearch />
            <SignUpBanner />
            <VacanciesOfTheDayMobile />
            <Column xs="4" s="0" m="0" l="0" container>
                <VSpacing base={6} />
                {bannersDashboardMobile?.map((banner: BannerObject) => (
                    <Fragment key={banner.id}>
                        <div onClick={() => handleBannerClick('XsBeforeCompanies')}>
                            <AdsExternalBanner {...banner} />
                        </div>
                        <VSpacing base={6} />
                    </Fragment>
                ))}
                <WorkInCompanyMobile />
            </Column>
            <RainbowCatalog />
            <RecommendedVacanciesMobile />
            <ColumnsWrapper>
                <Column xs="4" s="8" m="12" l="16" container>
                    {showWicAndVod && (
                        <Column xs="0" s="8" m="12" l="16" container>
                            <Column xs="0" s="4" m="8" l="12" container>
                                <Column xs="0" s="4" m="8" l="4" container>
                                    <VSpacing base={6} />
                                    <WorkInCompany />
                                </Column>
                                <Column xs="0" s="8" m="8" l="8">
                                    <VacanciesOfTheDay userType={UserType.Anonymous} />
                                </Column>
                            </Column>
                            <Column xs="0" s="4" m="4" l="4" container>
                                <VacanciesOfTheDayBanners />
                            </Column>
                        </Column>
                    )}
                    {bannersUnderRainbowMobile?.map((banner: BannerObject) => (
                        <Column xs="4" s="0" m="0" l="0" container key={banner.id}>
                            <div
                                className="index-section-app-banner"
                                onClick={() => handleBannerClick('XSAfterRainbow')}
                            >
                                <AdsExternalBanner {...banner} />
                            </div>
                        </Column>
                    ))}
                    <AppBanner />
                    {professionalRoleCategoryCollection && (
                        <Column xs="4" s="0" m="0" l="0">
                            <VSpacing base={6} />
                            <WorkInProfessionMobile />
                        </Column>
                    )}
                    <Column xs="0" s="8" m="12" l="16" container>
                        {!bannersHorizontal?.length && (
                            <Column xs="0" s="8" m="12" l="16">
                                <div className="separator" />
                            </Column>
                        )}
                        {bannersHorizontal?.map((banner: BannerObject) => (
                            <Column xs="0" s="8" m="12" l="16" key={banner.id}>
                                <VSpacing base={6} />
                                <AdsExternalBanner {...banner} />
                            </Column>
                        ))}
                    </Column>
                </Column>
                {chatBot && (
                    <>
                        <Column xs="4" s="0" m="0" l="0">
                            <div className="separator" />
                            <VSpacing base={6} />
                            <InternalPromoBlock isInline />
                            <div className="separator" />
                        </Column>
                    </>
                )}
                {!chatBot && stayInTouch.apps.length > 0 && (
                    <Column xs="0" s="0" m="0" l="0" container>
                        <VSpacing base={6} />
                        <StayInTouch isInline analyticsFrom="sms_main" />
                    </Column>
                )}
                <Column xs="4" s="8" m="12" l="16" container>
                    <VSpacing base={6} />
                    <div>
                        {professionalRoleCategoryCollection && (
                            <Column xs="0" s="8" m="12" l="12">
                                <WorkInProfession />
                            </Column>
                        )}
                        {chatBot && (
                            <>
                                <Column xs="0" s="8" m="12" l="0">
                                    <div className="separator" />
                                    <VSpacing base={6} />
                                    <InternalPromoBlock isInline />
                                </Column>
                                <Column xs="0" s="0" m="0" l="4">
                                    <InternalPromoBlock />
                                </Column>
                            </>
                        )}
                        {!chatBot && stayInTouch.apps.length > 0 && (
                            <>
                                <Column xs="0" s="8" m="12" l="0" container>
                                    <VSpacing base={6} />
                                    <StayInTouch isInline analyticsFrom="sms_main" />
                                </Column>
                                <Column xs="0" s="0" m="0" l="4" container>
                                    <StayInTouch analyticsFrom="sms_main" />
                                </Column>
                            </>
                        )}
                        <Column xs="0" s="8" m="12" l="16">
                            <div className="separator" />
                        </Column>
                        <Column xs="4" s="0" m="0" l="0" container>
                            <NewsBox
                                items={news}
                                title={trls[TrlKeys.newsTitle]}
                                href={siteNewsHref}
                                isEmployerContext={false}
                            />
                        </Column>
                        <Column xs="0" s="4" m="4" l={isZp ? '5' : '4'} container>
                            <NewsBox
                                items={news}
                                title={trls[TrlKeys.newsTitle]}
                                href={siteNewsHref}
                                isEmployerContext={false}
                                gapTop
                            />
                        </Column>
                        <Column xs="4" s="0" m="0" l="0">
                            {bannersUnderNewsBoxMobile?.map((banner: BannerObject) => (
                                <Fragment key={banner.id}>
                                    <VSpacing base={6} />
                                    <div onClick={() => handleBannerClick('XSAfterNews')}>
                                        <AdsExternalBanner {...banner} />
                                    </div>
                                    <VSpacing base={6} />
                                </Fragment>
                            ))}
                        </Column>
                        <Column xs="4" s="0" m="0" l="0" container>
                            <NewsBox
                                items={articles}
                                title={trls[TrlKeys.articlesTitle]}
                                href="/articles"
                                isArticle
                                isEmployerContext={false}
                                gapTop
                            />
                        </Column>
                        <Column xs="0" s="4" m="4" l={isZp ? '5' : '4'} container>
                            <NewsBox
                                items={articles}
                                title={trls[TrlKeys.articlesTitle]}
                                href="/articles"
                                isArticle
                                isEmployerContext={false}
                                gapTop
                            />
                        </Column>
                        <Column xs="0" s="8" m="0" l="0">
                            <div className="separator" />
                        </Column>
                        <Column xs="0" s="4" m="4" l={isZp ? '6' : '4'}>
                            <UsefulLinks title={trls[TrlKeys.usefulTitle]} items={useful} />
                        </Column>
                        {!isZp && (
                            <>
                                <Column xs="0" s="0" m="12" l="0">
                                    <div className="separator" />
                                </Column>
                                <Column xs="4" s="4" m="4" l="4">
                                    <VSpacing base={6} />
                                    <WorkInOtherCities />
                                </Column>
                            </>
                        )}
                    </div>
                </Column>
                <Column xs="0" s="8" m="12" l="16">
                    <div className="separator" />
                </Column>
                <WorkInCity />
                <CreateResumeButton />
                <Column xs="4" s="0" m="0" l="0">
                    {bannersUnderCreateResumeButton?.map((banner: BannerObject) => (
                        <Fragment key={banner.id}>
                            <div onClick={() => handleBannerClick('XSBottom')}>
                                <AdsExternalBanner {...banner} />
                            </div>
                            <VSpacing base={6} />
                        </Fragment>
                    ))}
                </Column>
            </ColumnsWrapper>
            <MainPageSignUpModal />
        </>
    );
};

export default translation(IndexAnonymous);
