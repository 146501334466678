import { FC, useEffect, useRef, useState } from 'react';

import { GridColumn, GridLayout, GridRow, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';

import EmployerAdvicesWidget from 'src/components/EmployerAdvicesWidget';
import EmployerBalanceWidget from 'src/components/EmployerBalanceWidget';
import EmployerBannersWidget from 'src/components/EmployerBannersWidget';
import EmployerResumeSearchesWidget from 'src/components/EmployerResumeSearchesWidget';
import SavedSearchesWidget from 'src/components/EmployerResumeSearchesWidget/SavedSearchesWidget';
import EmployerServicesWidget from 'src/components/EmployerServicesWidget';
import VacanciesWidget from 'src/components/EmployerVacanciesWidget';
import FAQ from 'src/components/FAQ';
import Blog from 'src/components/IndexPageEmployer/components/Blog';
import NewsOrArticlesCard from 'src/components/IndexPageEmployer/components/Blog/NewsOrArticlesCard';
import LastSearches from 'src/components/IndexPageEmployer/components/LastSearches';
import Updates from 'src/components/IndexPageEmployer/components/Updates';
import { useIsRussia } from 'src/hooks/useCountries';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { UXFeedback } from 'src/utils/uxfeedback';

const LAST_SEARCHES_MAX_HEIGHT = 256;
const COUNTRIES_DESKTOP_BANNER_HEIGHT = 320;
const COUNTRIES_DESKTOP_UPDATES_HEIGHT = 388;

const LayoutRedesign: FC = () => {
    const employerFaq = useSelector((state) => state.employerFaq);
    const isHiringManager = useSelector((state) => state.isHiringManager);
    const isZp = useIsZarplataPlatform();
    const bannersColumnRef = useRef<HTMLDivElement>(null);
    const [updatesHeight, setUpdatesHeight] = useState(0);
    const { breakpoint, isGtM } = useBreakpoint();
    const isRussia = useIsRussia();

    useEffect(() => {
        if (bannersColumnRef.current) {
            setUpdatesHeight(bannersColumnRef.current.getBoundingClientRect().height);
        }
    }, [breakpoint]);

    useEffect(() => {
        UXFeedback.sendEvent('main-page-redesign-visited');
    }, []);

    if (isZp) {
        return (
            <div className="main-page-redesign">
                <GridLayout>
                    <VSpacing xs={12} default={28} />
                    <GridRow rowGap={12}>
                        <GridColumn xs={4} s={8} m={0}>
                            <EmployerAdvicesWidget />
                        </GridColumn>
                        <GridColumn xs={4} s={8} m={0}>
                            <VacanciesWidget />
                        </GridColumn>
                    </GridRow>
                    <GridRow rowGap={24}>
                        <GridColumn xs={4} s={8} m={0}>
                            <VSpacing default={24} />
                            <EmployerBalanceWidget />
                        </GridColumn>
                        <GridColumn xs={4} s={8} m={0}>
                            <EmployerResumeSearchesWidget />
                        </GridColumn>
                        <GridColumn xs={4} s={8} m={0}>
                            <EmployerBannersWidget />
                        </GridColumn>
                        <GridColumn xs={4} s={8} m={0}>
                            <Blog showFaq faqMaxItems={6} />
                        </GridColumn>
                    </GridRow>
                    <GridRow rowGap={40}>
                        <GridColumn flexibleContent xs={0} s={0} m={4} l={3}>
                            <EmployerAdvicesWidget />
                            <VSpacing default={12} />
                            <EmployerBalanceWidget />
                            {isHiringManager && (
                                <>
                                    <VSpacing default={12} />
                                    <LastSearches maxItemsCount={2} maxHeight={LAST_SEARCHES_MAX_HEIGHT} />
                                </>
                            )}
                        </GridColumn>
                        <GridColumn flexibleContent xs={0} s={0} m={8} l={6}>
                            <VacanciesWidget />
                            <VSpacing default={12} />
                            <EmployerResumeSearchesWidget
                                hideSavedSearches={isGtM && isHiringManager}
                                hideLastSearches={isGtM}
                            />
                        </GridColumn>
                        <GridColumn flexibleContent xs={0} s={0} m={0} l={3}>
                            {isHiringManager ? <SavedSearchesWidget /> : <LastSearches maxItemsCount={3} />}
                            <VSpacing default={12} />
                            <EmployerBannersWidget bannerHeight={COUNTRIES_DESKTOP_BANNER_HEIGHT} />
                        </GridColumn>
                        <GridColumn ref={bannersColumnRef} flexibleContent xs={0} s={0} m={4} l={0}>
                            <EmployerBannersWidget />
                        </GridColumn>
                        <GridColumn xs={0} s={0} m={8} l={0}>
                            <Blog showFaq faqMaxItems={6} />
                        </GridColumn>
                    </GridRow>
                    <VSpacing default={40} xs={0} s={0} />
                    <GridRow>
                        <GridColumn xs={0} s={0} m={0} l={6}>
                            <NewsOrArticlesCard isArticle={false} />
                        </GridColumn>
                        <GridColumn xs={0} s={0} m={0} l={6}>
                            <NewsOrArticlesCard isArticle />
                        </GridColumn>
                    </GridRow>
                </GridLayout>
                <VSpacing default={80} xs={12} s={12} />
            </div>
        );
    }

    return (
        <div className="main-page-redesign">
            <GridLayout>
                <VSpacing xs={12} default={28} />
                <GridRow rowGap={12}>
                    <GridColumn xs={4} s={8} m={0}>
                        <EmployerAdvicesWidget />
                    </GridColumn>
                    <GridColumn xs={4} s={8} m={0}>
                        <VacanciesWidget />
                    </GridColumn>
                </GridRow>
                <GridRow rowGap={24}>
                    <GridColumn xs={4} s={8} m={0}>
                        <VSpacing default={24} />
                        <EmployerBalanceWidget />
                    </GridColumn>
                    <GridColumn xs={4} s={8} m={0}>
                        <EmployerResumeSearchesWidget />
                    </GridColumn>
                    {isRussia && (
                        <GridColumn xs={4} s={8} m={0}>
                            <EmployerServicesWidget />
                        </GridColumn>
                    )}
                    <GridColumn xs={4} s={8} m={0}>
                        <EmployerBannersWidget />
                    </GridColumn>
                    <GridColumn xs={4} s={8} m={0}>
                        <Blog showFaq faqMaxItems={6} />
                    </GridColumn>
                    <GridColumn xs={4} s={8} m={0}>
                        <Updates />
                    </GridColumn>
                </GridRow>
                <GridRow rowGap={40}>
                    <GridColumn flexibleContent xs={0} s={0} m={4} l={3}>
                        <EmployerAdvicesWidget />
                        <VSpacing default={12} />
                        <EmployerBalanceWidget />
                        {isHiringManager && (
                            <>
                                <VSpacing default={12} />
                                <LastSearches maxItemsCount={2} maxHeight={LAST_SEARCHES_MAX_HEIGHT} />
                            </>
                        )}
                    </GridColumn>
                    <GridColumn flexibleContent xs={0} s={0} m={8} l={6}>
                        <VacanciesWidget />
                        <VSpacing default={12} />
                        <EmployerResumeSearchesWidget hideLastSearches={isHiringManager} />
                    </GridColumn>
                    <GridColumn flexibleContent xs={0} s={0} m={0} l={3}>
                        {isRussia ? (
                            <EmployerServicesWidget />
                        ) : (
                            <Updates maxHeight={COUNTRIES_DESKTOP_UPDATES_HEIGHT} />
                        )}
                        <VSpacing default={12} />
                        <EmployerBannersWidget bannerHeight={isRussia ? undefined : COUNTRIES_DESKTOP_BANNER_HEIGHT} />
                    </GridColumn>
                    <GridColumn flexibleContent xs={0} s={0} m={4} l={0}>
                        <Updates maxHeight={updatesHeight} />
                    </GridColumn>
                    <GridColumn ref={bannersColumnRef} flexibleContent xs={0} s={0} m={4} l={0}>
                        <EmployerBannersWidget />
                    </GridColumn>
                    <GridColumn flexibleContent xs={0} s={0} m={4} l={0}>
                        {isRussia ? (
                            <EmployerServicesWidget />
                        ) : (
                            <FAQ maxCount={5} verticalStretched items={employerFaq} />
                        )}
                    </GridColumn>
                    <GridColumn xs={0} s={0} m={8} l={0}>
                        <Blog showFaq={isRussia} faqMaxItems={6} />
                    </GridColumn>
                </GridRow>
                <VSpacing default={40} xs={0} s={0} />
                <GridRow>
                    <GridColumn xs={0} s={0} m={0} l={6}>
                        <Blog />
                    </GridColumn>
                    {isRussia ? (
                        <>
                            <GridColumn xs={0} s={0} m={0} l={3}>
                                <Updates />
                            </GridColumn>
                            <GridColumn flexibleContent xs={0} s={0} m={0} l={3}>
                                <FAQ maxCount={5} verticalStretched items={employerFaq} />
                            </GridColumn>
                        </>
                    ) : (
                        <GridColumn flexibleContent xs={0} s={0} m={0} l={6}>
                            <FAQ maxCount={5} verticalStretched items={employerFaq} />
                        </GridColumn>
                    )}
                </GridRow>
            </GridLayout>
            <VSpacing default={80} xs={12} s={12} />
        </div>
    );
};

export default LayoutRedesign;
