import { useSelector } from 'src/hooks/useSelector';

const URL_BASE_TALANTIX_PROMO = 'https://talantix.ru/?utm_source=hh.ru&utm_medium=referral&utm_campaign=';

export const UTM_CAMPAIGN_MAIN_WIDGET_CONNECTED_SERVICE = 'main_widget_connected_service';
export const UTM_CAMPAIGN_MAIN_WIDGET_UNCONNECTED_SERVICE = 'main_widget_unconnected_service';
export const UTM_CAMPAIGN_WORKFLOW_BAZA = 'workflow_baza';
export const UTM_CAMPAIGN_FROM_VACANCY_RESPONSES_DISAPPEARED = 'from_vac_response_disappeared';
export const UTM_CAMPAIGN_FAVOURITE_RESUME = 'favourite_resume';
export const UTM_CAMPAIGN_RESUME_TRANSFER = 'resume_transfer';
export const UTM_CAMPAIGN_PRICE_OFFER = 'price_offer';

type UtmCampaign =
    | typeof UTM_CAMPAIGN_MAIN_WIDGET_CONNECTED_SERVICE
    | typeof UTM_CAMPAIGN_MAIN_WIDGET_UNCONNECTED_SERVICE
    | typeof UTM_CAMPAIGN_WORKFLOW_BAZA
    | typeof UTM_CAMPAIGN_FROM_VACANCY_RESPONSES_DISAPPEARED
    | typeof UTM_CAMPAIGN_FAVOURITE_RESUME
    | typeof UTM_CAMPAIGN_RESUME_TRANSFER
    | typeof UTM_CAMPAIGN_PRICE_OFFER;

const useTalantixPromoUrl = (utmCampaign: UtmCampaign): string => {
    const employerId = useSelector((state) => state.employerId);

    if (employerId) {
        return `${URL_BASE_TALANTIX_PROMO}${utmCampaign}&employer_id=${employerId}&utm_term=withid`;
    }

    return `${URL_BASE_TALANTIX_PROMO}${utmCampaign}&utm_term=withoutid`;
};

export { useTalantixPromoUrl };
