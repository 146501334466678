import { Button, Text } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import AdviceCard from 'src/components/EmployerAdvicesWidget/AdviceCard';
import { useHandleAnalyticsCardClick } from 'src/components/EmployerAdvicesWidget/useHandleAnalyticsCardClick';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { OpenEmployerCongratulationAdvice } from 'src/models/employerAdvicesWidget/advices';
import { fetcher } from 'src/utils/fetcher';

const TrlKeys = {
    text: 'employer.index.advicesWidget.dreamjobOpenCongratulation.text',
    highlighted: 'employer.index.advicesWidget.dreamjobOpenCongratulation.highlighted',
    button: 'employer.index.advicesWidget.dreamjobOpenCongratulation.button',
};

const MAKE_READ_URL = '/shards/employer/open_employer_make_read';

declare global {
    interface FetcherGetApi {
        [MAKE_READ_URL]: {
            response: void;
            queryParams: void;
        };
    }
}

const OpenEmployerCongratulation: TranslatedComponent<OpenEmployerCongratulationAdvice> = ({ trls, name }) => {
    const { addNotification } = useNotification();

    const sendAnalytics = useHandleAnalyticsCardClick(name);

    const makeRead = async () => {
        try {
            await fetcher.get(MAKE_READ_URL);
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
        }
    };

    const handleClick = () => {
        sendAnalytics();
        void makeRead();
    };
    return (
        <AdviceCard
            name={name}
            button={
                <Button
                    Element={SPALink}
                    to="/employer/reviews-settings"
                    size="small"
                    stretched
                    mode="secondary"
                    style="neutral"
                    onClick={handleClick}
                >
                    {trls[TrlKeys.button]}
                </Button>
            }
        >
            {formatToReactComponent(trls[TrlKeys.text], {
                '{highlighted}': (
                    <Text typography="title-5-semibold" Element="span" style="special">
                        {trls[TrlKeys.highlighted]}
                    </Text>
                ),
            })}
        </AdviceCard>
    );
};

export default translation(OpenEmployerCongratulation);
