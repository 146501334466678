import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface BannerObject {
    id: string;
    cId: string;
    bannerAdTargeting?: Record<string, unknown>;
}

// Не забудь продублировать в константы в  places.py!!!
/* eslint-disable @typescript-eslint/naming-convention */
export enum BannerPlace {
    AFTER_4TH_TOPIC_L = 'after-4th-topic-l',
    AFTER_4TH_TOPIC_M = 'after-4th-topic-m',
    AFTER_4TH_TOPIC_S = 'after-4th-topic-s',
    AFTER_4TH_TOPIC_XS = 'after-4th-topic-xs',
    AFTER_PAYMENT_FORM_L = 'after-payment-form-l',
    AFTER_PAYMENT_FORM_S_M = 'after-payment-form-s-m',
    AFTER_PAYMENT_FORM_XS = 'after-payment-form-xs',
    AFTER_VACANCY_RESPONSE = 'after-vacancy-response',
    BOTTOM_DASHBOARD = 'bottom-dashboard',
    BOTTOM_DASHBOARD_CLICKME = 'bottom-dashboard-clickme',
    CALENDAR = 'calendar',
    DASHBOARD = 'dashboard',
    INDEX_BOTTOM = 'index-bottom',
    INDEX_BOTTOM_MOBILE = 'index-bottom-mobile',
    INDEX_BOTTOM_XS = 'index-bottom-xs',
    INDEX_DASHBOARD_MOBILE = 'index-dashboard-mobile',
    INDEX_HORIZONTAL = 'index-horizontal',
    INDEX_UNDER_NEWS_BOX = 'index-under-news-box',
    INDEX_UNDER_DASHBOARD_SMALL = 'index-under-dashboard-small',
    INDEX_VOD = 'index-vod',
    INDEX_UNDER_RAINBOW_MOBILE = 'index-under-rainbow-mobile',
    INDEX_UNDER_NEWS_BOX_MOBILE = 'index-under-news-box-mobile',
    INDEX_WORK_IN_COMPANY = 'index-work-in-company',
    INDEX_WORK_IN_COMPANY_MOBILE = 'index-work-in-company-mobile',
    LEFT_COLUMN_UNDER_TABS = 'left-column-under-tabs',
    RIGHT_COLUMN = 'right-column',
    RIGHT_COLUMN_CLICKME = 'right-column-clickme',
    RIGHT_COLUMN_CLICKME_ADAPTIVE = 'right-column-clickme-adaptive',
    SERP_FIFTH_L = 'serp-fifth-l',
    SERP_FIFTH_M = 'serp-fifth-m',
    SERP_FIFTH_S = 'serp-fifth-s',
    SERP_FIFTH_XS = 'serp-fifth-xs',
    SERP_FIRST = 'serp-first',
    SERP_FIRST_SMALL = 'serp-first-small',
    SERP_FIRST_L = 'serp-first-l',
    SERP_FIRST_M = 'serp-first-m',
    SERP_FIRST_S = 'serp-first-s',
    SERP_FIRST_XS = 'serp-first-xs',
    SERP_FOURTH_L = 'serp-fourth-l',
    SERP_FOURTH_M = 'serp-fourth-m',
    SERP_FOURTH_S = 'serp-fourth-s',
    SERP_FOURTH_XS = 'serp-fourth-xs',
    SERP_SECOND = 'serp-second',
    SERP_SECOND_SMALL = 'serp-second-small',
    SERP_SECOND_L = 'serp-second-l',
    SERP_SECOND_M = 'serp-second-m',
    SERP_SECOND_S = 'serp-second-s',
    SERP_SECOND_XS = 'serp-second-xs',
    SERP_THIRD_L = 'serp-third-l',
    SERP_THIRD_M = 'serp-third-m',
    SERP_THIRD_S = 'serp-third-s',
    SERP_THIRD_XS = 'serp-third-xs',
    UNDER_PAGINATION_SEARCH_RESULTS_L = 'under-pagination-search-results-l',
    UNDER_PAGINATION_SEARCH_RESULTS_M = 'under-pagination-search-results-m',
    UNDER_PAGINATION_SEARCH_RESULTS_S = 'under-pagination-search-results-s',
    UNDER_PAGINATION_SEARCH_RESULTS_XS = 'under-pagination-search-results-xs',
    UNDER_SEARCH_RESULTS_ROTATE = 'under-search-results-rotate',
    UNDER_SEARCH_RESULTS_XS = 'under-search-results-xs',
    VACANCY_PROMOTION = 'vacancy-promotion',
    VACANCY_RECOMMENDATIONS = 'vacancy-recommendations',
    VACANCY_RIGHT_TEASER = 'vacancy-right-teaser',
    VACANCY_VIEW_SERP_FIRST_L = 'vacancy-view-serp-first-l',
    VACANCY_VIEW_SERP_FIRST_S_M = 'vacancy-view-serp-first-s-m',
    VACANCY_VIEW_SERP_FIRST_XS = 'vacancy-view-serp-first-xs',
    VACANCY_VIEW_SERP_FIRST_ODD_L = 'vacancy-view-serp-first-odd-l',
    VACANCY_VIEW_SERP_FIRST_ODD_S_M = 'vacancy-view-serp-first-odd-s-m',
    VACANCY_VIEW_SERP_FIRST_ODD_XS = 'vacancy-view-serp-first-odd-xs',

    // tempexp_32927_start
    INDEX_VOD_MAGRITTE_ANONYMOUS = 'index-vod-magritte-anonymous',
    INDEX_WORK_IN_COMPANY_MAGRITTE_ANONYMOUS = 'index-work-in-company-magritte-anonymous',
    INDEX_DASHBOARD_MOBILE_MAGRITTE_ANONYMOUS = 'index-dashboard-mobile-magritte-anonymous',
    INDEX_UNDER_RAINBOW_MOBILE_MAGRITTE_ANONYMOUS = 'index-under-rainbow-mobile-magritte-anonymous',
    INDEX_UNDER_NEWS_BOX_MOBILE_MAGRITTE_ANONYMOUS = 'index-under-news-box-mobile-magritte-anonymous',
    INDEX_BOTTOM_MOBILE_MAGRITTE_ANONYMOUS = 'index-bottom-mobile-magritte-anonymous',
    // tempexp_32927_end
    // tempexp_vacancy_clickme_banners_replace_abc_next-line
    TEST_MAGRITTE_VACANCY_RIGHT_COLUMN = 'test-magritte-vacancy-right-column',
}
/* eslint-enable @typescript-eslint/naming-convention */
// Не забудь продублировать в константы в  places.py!!!

export interface Banners {
    dashboard?: BannerObject[];
    [BannerPlace.INDEX_BOTTOM]?: BannerObject[];
    [BannerPlace.INDEX_BOTTOM_MOBILE]?: BannerObject[];
    [BannerPlace.INDEX_DASHBOARD_MOBILE]?: BannerObject[];
    [BannerPlace.INDEX_HORIZONTAL]?: BannerObject[];
    [BannerPlace.INDEX_UNDER_NEWS_BOX]?: BannerObject[];
    [BannerPlace.INDEX_UNDER_NEWS_BOX_MOBILE]?: BannerObject[];
    [BannerPlace.INDEX_UNDER_RAINBOW_MOBILE]?: BannerObject[];
    [BannerPlace.INDEX_VOD]?: BannerObject[];
}

const modelsbanners = autoGeneratedReducer<Banners>({});
export { modelsbanners };
