import { Fragment, useRef, useEffect, useState } from 'react';

import { Cell, CellText, Text as MagritteText, Link, Drop, BottomSheet, useBreakpoint } from '@hh.ru/magritte-ui';
import { GlobeOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Conversion from 'bloko/blocks/conversion';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import paths from 'src/app/routePaths';
import indexPageEmployerAnalytics from 'src/components/IndexPageEmployer/components/analytics';
import translation from 'src/components/translation';
import { useFeatureEnabled } from 'src/hooks/useFeatureEnabled';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { CurrencyType } from 'src/models/currencies.types';
import { ServiceEmployerDescription } from 'src/models/oldEmployer/employerBillingSummary';
import { additionalPurchaseCodes, ServiceCode } from 'src/models/price/productCodes';

import getTranslationsFor from 'src/components/IndexPageEmployer/components/EmployerEventList/getTranslationsFor';

const COULD_BE_WITH_CIV = [ServiceCode.Disposable, ...additionalPurchaseCodes];
const RESUME_ACCESS_CODES = [
    ServiceCode.ContactsViewWithStandart,
    ServiceCode.FullAccess,
    ServiceCode.FullAccessWithStandart,
    ServiceCode.Bundle,
];

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

const TrlKeys = {
    buy: 'index.employer.billing.buy',
    resumeAccess: 'index.employer.billing.resumeAccess',
    [ServiceCode.Reservation]: 'employer.accountHistory.availableServices.RESERVATION.CIV',
    [ServiceCode.Add]: 'employer.accountHistory.availableServices.ADD.CIV',
    areaAll: 'employer.price.area.all',
    allProfRoleGroups: 'billing_professional_role_group.0',
    balanceEmpty: 'employer.service.balance.empty',
    serviceBalance: 'employer.service.balance',
    expiresSoon: 'index.employer.billing.expiresSoon',
    expires: 'index.employer.billing.expires',
    activeTo: 'price.already-purchased.active-to',
    activeSins: 'price.already-purchased.active-sins',
    contactOpening: {
        some: 'employer.service.contact.opening.some',
        one: 'employer.service.contact.opening.one',
        many: 'employer.service.contact.opening.many',
    },
    genitive: {
        month: {
            1: 'genitive.month.1',
            2: 'genitive.month.2',
            3: 'genitive.month.3',
            4: 'genitive.month.4',
            5: 'genitive.month.5',
            6: 'genitive.month.6',
            7: 'genitive.month.7',
            8: 'genitive.month.8',
            9: 'genitive.month.9',
            10: 'genitive.month.10',
            11: 'genitive.month.11',
            12: 'genitive.month.12',
        },
    },
    days: {
        one: 'index.employer.billing.days.one',
        some: 'index.employer.billing.days.some',
        many: 'index.employer.billing.days.many',
    },
    access: 'index.employer.billing.access.common',
};

const BalanceAccess: TranslatedComponent = ({ trls }) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const employerIndexPage = useSelector((state) => state.employerIndexPage);
    const isZp = useIsZarplataPlatform();
    const databaseAccessLinkRef = useRef(null);
    const databaseAccessBuyLinkRef = useRef(null);
    const [dropOpen, setDropOpen] = useState(false);
    const { isMobile } = useBreakpoint();
    const rootRef = useRef(null);

    const getPurchasedAccessCivBalance = (item: ServiceEmployerDescription) => {
        const { civBalance } = item;

        if (civBalance === undefined || civBalance === null) {
            return null;
        }

        let content;

        if (civBalance === 0) {
            content = format(trls[TrlKeys.balanceEmpty], {
                '{0}': trls[TrlKeys.contactOpening.some],
            });
        } else {
            content = (
                <Conversion
                    one={trls[TrlKeys.contactOpening.one]}
                    some={trls[TrlKeys.contactOpening.some]}
                    many={trls[TrlKeys.contactOpening.many]}
                    value={civBalance}
                    hasValue={false}
                    format={(trl) =>
                        format(trls[TrlKeys.serviceBalance], {
                            '{0}': civBalance,
                            '{1}': trl,
                        })
                    }
                />
            );
        }

        return (
            <>
                <VSpacing base={1} />
                <Text size={TextSize.Small} importance={TextImportance.Tertiary}>
                    <div className="purchased-service-sentence">{content}</div>
                </Text>
            </>
        );
    };

    const getActiveDate = (expirationTime: ServiceEmployerDescription['expirationTime']) => {
        if (expirationTime.remainingDays === undefined || expirationTime.remainingDays === null) {
            return (
                <Text size={TextSize.Small} importance={TextImportance.Tertiary}>
                    {trls[TrlKeys.activeTo]} {expirationTime.day} {trls[TrlKeys.genitive.month[expirationTime.month]]}{' '}
                    {expirationTime.year}
                </Text>
            );
        }

        if (expirationTime.remainingDays === 0) {
            return (
                <Text size={TextSize.Small}>
                    <span className="purchased-service-expires-soon">{trls[TrlKeys.expiresSoon]}</span>
                </Text>
            );
        }
        return (
            <Text size={TextSize.Small}>
                <span className="purchased-service-expires-soon">
                    <Conversion
                        one={trls[TrlKeys.days.one]}
                        some={trls[TrlKeys.days.many]}
                        many={trls[TrlKeys.days.some]}
                        value={expirationTime.remainingDays}
                        hasValue={false}
                        format={(trl) =>
                            format(trls[TrlKeys.expires], {
                                '{0}': expirationTime.remainingDays,
                                '{1}': trl,
                            })
                        }
                    />
                </span>
            </Text>
        );
    };

    const getFutureDate = (
        expirationTime: ServiceEmployerDescription['expirationTime'],
        activationTime: ServiceEmployerDescription['activationTime']
    ) => {
        return (
            <>
                <VSpacing base={1} />
                <Text size={TextSize.Small} importance={TextImportance.Tertiary}>
                    {format(trls[TrlKeys.activeSins], {
                        '{0}': activationTime.day,
                        '{1}': trls[TrlKeys.genitive.month[activationTime.month]],
                        '{2}': activationTime.year,
                    })}
                </Text>
                <Text size={TextSize.Small} importance={TextImportance.Tertiary}>
                    {expirationTime &&
                        `${trls[TrlKeys.activeTo]} ${expirationTime.day} ${
                            trls[TrlKeys.genitive.month[expirationTime.month]]
                        } ${expirationTime.year}`}
                </Text>
            </>
        );
    };

    const purchasedServicesDate = (item: ServiceEmployerDescription) => {
        const { status, expirationTime, activationTime } = item;

        if (status === 'active') {
            return getActiveDate(expirationTime);
        }

        if (status === 'future') {
            return getFutureDate(expirationTime, activationTime);
        }

        return null;
    };

    const getPurchasedResumeAccessesItem = (item: ServiceEmployerDescription) => {
        return (
            <div className="purchased-services">
                <div className="purchased-service" data-qa={`purchased-service-${item.code}`}>
                    <div className="purchased-service-name">
                        <Text Element="p" strong>
                            {item.availableServicesTrl}
                        </Text>
                        <VSpacing base={2} />
                        <Text Element="p">{getTranslationsFor(item.priceAreaTrl, trls[TrlKeys.areaAll])}</Text>
                        <Text>
                            {getTranslationsFor(
                                item.profRoleGroupIdsTrl ? item.profRoleGroupIdsTrl : item.professionIdsTrl,
                                trls[TrlKeys.allProfRoleGroups]
                            )}
                        </Text>
                        {getPurchasedAccessCivBalance(item)}
                        {purchasedServicesDate(item)}
                    </div>
                </div>
            </div>
        );
    };

    const getPurchasedResumeAccessesList = () => {
        if (employerIndexPage.oldEmployer) {
            const { serviceDescription } = employerIndexPage.oldEmployer.employer;

            // TODO параметр temporalCiv определяет есть ли в DI услуги CIV состовляющая
            const filteredServiceDescription = serviceDescription.filter(
                (item) =>
                    RESUME_ACCESS_CODES.includes(item.code) ||
                    (COULD_BE_WITH_CIV.includes(item.code) &&
                        typeof item.civBalance === 'number' &&
                        item.civBalance >= 0 &&
                        item.temporalCiv)
            );

            return filteredServiceDescription.map((service, index) => (
                <Fragment key={index}>{getPurchasedResumeAccessesItem(service)}</Fragment>
            ));
        }

        return null;
    };

    const activeResumeAccessesInfo =
        employerIndexPage.oldEmployer?.billingSummary?.serviceCategories?.resumeAccessCodes || {};

    const currency = employerIndexPage.oldEmployer?.employer?.currency || null;

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled) {
            if (databaseAccessLinkRef.current) {
                indexPageEmployerAnalytics.myEventsDatabaseAccessShown(databaseAccessLinkRef.current);
            }
            if (databaseAccessBuyLinkRef.current) {
                indexPageEmployerAnalytics.myEventsDatabaseAccessBuyShown(databaseAccessBuyLinkRef.current);
            }
        }
    }, [employerMainPageAnalyticsEnabled]);

    if (currency === CurrencyType.GEL) {
        return null;
    }

    if (activeResumeAccessesInfo.count || activeResumeAccessesInfo.remainingDays) {
        return (
            <div className="my-events__item_access" ref={rootRef}>
                <Cell
                    data-qa="my-events-dbaccess"
                    left={<GlobeOutlinedSize24 />}
                    right={
                        <MagritteText typography="label-2-regular" style="secondary">
                            <span className="my-events__count">
                                {typeof activeResumeAccessesInfo.remainingDays === 'number' &&
                                activeResumeAccessesInfo.remainingDays > 0 ? (
                                    <Conversion
                                        hasValue
                                        one={trls[TrlKeys.days.one]}
                                        some={trls[TrlKeys.days.many]}
                                        many={trls[TrlKeys.days.some]}
                                        value={activeResumeAccessesInfo.remainingDays}
                                    />
                                ) : (
                                    `${activeResumeAccessesInfo.count} ${trls[TrlKeys.access]}`
                                )}
                            </span>
                        </MagritteText>
                    }
                    onClick={() => {
                        setDropOpen((open) => !open);
                    }}
                >
                    <a
                        ref={databaseAccessLinkRef}
                        data-qa="my-events-dbaccess-name"
                        onClick={
                            employerMainPageAnalyticsEnabled
                                ? indexPageEmployerAnalytics.myEventsDatabaseAccessClick
                                : undefined
                        }
                    >
                        <CellText>{trls[TrlKeys.resumeAccess]}</CellText>
                    </a>
                </Cell>

                <Drop
                    activatorRef={rootRef}
                    placement="right-top"
                    visible={dropOpen}
                    onClose={() => {
                        setDropOpen(false);
                    }}
                >
                    {getPurchasedResumeAccessesList()}
                </Drop>

                <BottomSheet
                    visible={isMobile && dropOpen}
                    onClose={() => {
                        setDropOpen(false);
                    }}
                >
                    {getPurchasedResumeAccessesList()}
                </BottomSheet>
            </div>
        );
    }

    const linkResumeAccessUrl = isZp ? paths.priceZpResumeAccess : paths.priceDBAccess;

    return (
        <div className="my-events__item_access" ref={rootRef}>
            <Cell
                data-qa="my-events-dbaccess-access"
                left={<GlobeOutlinedSize24 />}
                right={
                    <div ref={databaseAccessBuyLinkRef}>
                        <Link
                            href={linkResumeAccessUrl}
                            data-qa="my-events-dbaccess-access-buy"
                            onClick={
                                employerMainPageAnalyticsEnabled
                                    ? indexPageEmployerAnalytics.myEventsDatabaseAccessBuyClick
                                    : undefined
                            }
                        >
                            {trls[TrlKeys.buy]}
                        </Link>
                    </div>
                }
            >
                <a
                    ref={databaseAccessLinkRef}
                    href={linkResumeAccessUrl}
                    data-qa="my-events-dbaccess-access-name"
                    onClick={
                        employerMainPageAnalyticsEnabled
                            ? indexPageEmployerAnalytics.myEventsDatabaseAccessClick
                            : undefined
                    }
                >
                    <CellText>{trls[TrlKeys.resumeAccess]}</CellText>
                </a>
            </Cell>
        </div>
    );
};

export default translation(BalanceAccess);
