import { Text } from '@hh.ru/magritte-ui';
import { ChartLineOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ServiceCard from 'src/components/EmployerServicesWidget/ServiceCard';
import { useHandleAnalyticsCardClick } from 'src/components/EmployerServicesWidget/useHandleAnalyticsCardClick';
import translation from 'src/components/translation';
import { EmployerServiceWidget } from 'src/models/employerServicesWidget';

const TrlKeys = {
    title: 'employer.index.servicesWidget.analytics.title',
    connected: 'employer.index.servicesWidget.see.button',
};

const AnalyticsServiceCard: TranslatedComponent<EmployerServiceWidget> = ({ trls, name, active }) => {
    const handleClick = useHandleAnalyticsCardClick({ name, active });
    return (
        <SPALink onClick={handleClick} target="_blank" to="/employer/statistics/competitor_analysis">
            <ServiceCard
                name={name}
                isActive
                title={trls[TrlKeys.title]}
                icon={<ChartLineOutlinedSize24 initialColor="accent" />}
            >
                <Text typography="subtitle-1-semibold">{trls[TrlKeys.connected]}</Text>
            </ServiceCard>
        </SPALink>
    );
};

export default translation(AnalyticsServiceCard);
