import { useExperiment } from '@hh.ru/front-static-app';
import { Title, Text, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { SavedSearch } from 'src/models/employer/employerLastSearches';

import SavedSearchItem from 'src/components/IndexPageEmployer/components/LastSearches/SavedSearchItem';

const TrlKeys = {
    title: 'index.employerLastSearches.title',
};

interface SearchListProps {
    searches: SavedSearch[];
}

const SearchList: TranslatedComponent<SearchListProps> = ({ trls, searches }) => {
    const isMainPageRedesign = useExperiment('main_page_redesign_v1', true, false);

    return (
        <div data-qa="last-searches" className="last-searches">
            {isMainPageRedesign ? (
                <Text typography="subtitle-1-semibold" data-qa="last-searches-title">
                    {trls[TrlKeys.title]}
                </Text>
            ) : (
                <Title Element="h2" size="small" data-qa="last-searches-title">
                    {trls[TrlKeys.title]}
                </Title>
            )}

            <VSpacing default={isMainPageRedesign ? 16 : 20} />

            <ul className="last-searches__list">
                {searches.map(({ id, text, searchQuery, searchCriteria }, index) => (
                    <SavedSearchItem
                        key={id}
                        id={id}
                        text={text}
                        searchQuery={searchQuery}
                        searchCriteria={searchCriteria}
                        position={index + 1}
                    />
                ))}
            </ul>
        </div>
    );
};

export default translation(SearchList);
