import { SPALink } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import { H2 } from 'bloko/blocks/header';
import BlokoLink from 'bloko/blocks/link';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { Bound } from 'bloko/common/elementSpy';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Compensation from 'src/components/Compensation';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { VacancyOfTheDayLocation } from 'src/utils/sendAdvSpyAnalytics';

import { getVacancyLink } from 'src/components/VacanciesOfTheDay/Utils';
import { sendAnalytics } from 'src/components/VacanciesOfTheDay/analytics';
import { MIN_VACANCIES_NUM_TO_SHOW } from 'src/components/VacanciesOfTheDay/constants';
import { useVacanciesOfTheDayViewAnalytics } from 'src/components/VacanciesOfTheDay/hooks/useVacanciesOfTheDayViewAnalytics';

const TrlKeys = {
    title: 'professions.promo.vacancies',
};

const trackingParams = { trackingBounds: [Bound.Left] };

const VacanciesOfTheDayMobile: TranslatedComponent = ({ trls }) => {
    const vacanciesOfTheDay = useSelector((state) => state.vacanciesOfTheDay);
    const breakpoint = useBreakpoint();
    const isXs = breakpoint === Breakpoint.XS;

    const vacanciesRefs = useVacanciesOfTheDayViewAnalytics(
        vacanciesOfTheDay.vacancies,
        VacancyOfTheDayLocation.MainPageXsSlider,
        trackingParams
    );

    if (!isXs || !vacanciesOfTheDay.success || vacanciesOfTheDay.vacancies.length <= MIN_VACANCIES_NUM_TO_SHOW) {
        return null;
    }

    return (
        <Column xs="4" s="0" m="0" l="0" container>
            <div className="index-section-vacancies-of-the-day">
                <Column xs="4" s="0" m="0" l="0">
                    <H2>{trls[TrlKeys.title]}</H2>
                    <VSpacing base={4} />
                </Column>
                <div className="index-slider-wrapper">
                    <div className="index-slider">
                        {vacanciesOfTheDay.vacancies.map((vacancy) => {
                            const titleLinkContent = (
                                <>
                                    <span
                                        className="index-slider-vacancy-title"
                                        data-qa="mobile-vod-title"
                                        ref={(ref) => {
                                            if (ref && vacanciesRefs.current) {
                                                vacanciesRefs.current[vacancy.vacancyId] = ref;
                                            }
                                        }}
                                    >
                                        <Text Element="span" strong>
                                            {vacancy.name}
                                        </Text>
                                    </span>
                                    <span
                                        className="index-slider-vacancy-compensation"
                                        data-qa="mobile-vod-compensation"
                                    >
                                        <Compensation
                                            {...vacancy.compensation}
                                            analyticsContext="VacanciesOfTheDayMobile"
                                            showNoSalaryMessage
                                        />
                                    </span>
                                    {vacancy.company.id && (
                                        <span className="index-slider-vacancy-company" data-qa="mobile-vod-company">
                                            <Text Element="span" importance={TextImportance.Secondary}>
                                                {`${vacancy.company.visibleName}, ${vacancy.area.name}`}
                                            </Text>
                                        </span>
                                    )}
                                </>
                            );

                            return (
                                <div className="index-slider-item" key={vacancy.vacancyId}>
                                    <BlokoLink
                                        Element={SPALink}
                                        to={getVacancyLink(vacancy, VacancyOfTheDayLocation.MainPageXsSlider)}
                                        additionalQueryParams={{ source: 'vacancies_of_the_day' }}
                                        onClick={() => sendAnalytics(vacancy.vacancyId, 'vacancy_of_the_day')}
                                    >
                                        <span className="index-slider-vacancy index-slider-vacancy-wrapper">
                                            {titleLinkContent}
                                        </span>
                                    </BlokoLink>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Column>
    );
};

export default translation(VacanciesOfTheDayMobile);
