import { FC, Fragment, useMemo, useState } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Divider, VSpacing } from '@hh.ru/magritte-ui';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhKz, useIsHhru } from 'src/hooks/useSites';
import { getPfpBalanceWarning } from 'src/models/payForPerformance/pfpBalanceWarning';
import { PfpStatus } from 'src/models/payForPerformance/pfpInfo';

import CollapsibleContainer from 'src/components/EmployerBalanceWidget/CollapsibleContainer';
import ClickmeBalanceCard from 'src/components/EmployerBalanceWidget/cards/ClickmeBalanceCard';
import HHEmployeesCard from 'src/components/EmployerBalanceWidget/cards/HHEmployeesCard';
import MainBalanceCard from 'src/components/EmployerBalanceWidget/cards/MainBalanceCard';
import PfpBalanceCard from 'src/components/EmployerBalanceWidget/cards/PfpBalanceCard';
import PublicationsCard from 'src/components/EmployerBalanceWidget/cards/PublicationsCard';
import ResumeAccessCard from 'src/components/EmployerBalanceWidget/cards/ResumeAccessCard';
import ServicesCard from 'src/components/EmployerBalanceWidget/cards/ServicesCard';

import styles from './styles.less';

const EmployerBalanceWidget: FC = () => {
    const employerIndexPage = useSelector((state) => state.employerIndexPage);

    const isHiringManager = useSelector((state) => state.isHiringManager);
    const isZp = useIsZarplataPlatform();
    const isHHRu = useIsHhru();
    const isKZ = useIsHhKz();

    const pfpInfo = employerIndexPage.oldEmployer?.pfpInfo;
    const havePfpVacancies = employerIndexPage.oldEmployer?.havePfpVacancies;
    const pfpDisabled = !pfpInfo || pfpInfo?.pfpStatus === PfpStatus.Disabled;
    const pfpBalanceWarning = pfpInfo && !pfpDisabled && havePfpVacancies ? getPfpBalanceWarning(pfpInfo) : null;

    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleCollapsedToggle = () => {
        Analytics.sendHHEventButtonClick('balance_widget_collapse_button', { isOpen: isCollapsed });
        setIsCollapsed((prev) => !prev);
    };

    const dropCards = useMemo(() => {
        const cards = [];

        if (!pfpDisabled) {
            cards.push(<PfpBalanceCard pfpBalanceWarning={pfpBalanceWarning} key="pfp" />);
        }

        if (!isZp && isHHRu) {
            cards.push(<ClickmeBalanceCard key="clickme" />);
        }

        if (isKZ || (isHHRu && !isZp)) {
            cards.push(<HHEmployeesCard key="hhEmployees" />);
        }

        return cards;
    }, [isHHRu, isKZ, isZp, pfpDisabled, pfpBalanceWarning]);

    if (isHiringManager) {
        return (
            <div className={styles.cards}>
                <ResumeAccessCard />
                <ServicesCard />
            </div>
        );
    }

    return (
        <CollapsibleContainer
            isCollapsed={isCollapsed}
            hasProblem={!!pfpBalanceWarning}
            onCollapsedToggle={handleCollapsedToggle}
            mainCard={<MainBalanceCard />}
            dropCardsCount={dropCards.length}
            collapsedCards={
                <>
                    <PublicationsCard />
                    <ResumeAccessCard />
                    <ServicesCard />
                </>
            }
        >
            <Divider marginTop={24} marginBottom={4} />
            {dropCards.map((card, index) => {
                return (
                    <Fragment key={index}>
                        {card}
                        {index !== dropCards.length - 1 && <Divider marginTop={4} marginBottom={4} />}
                    </Fragment>
                );
            })}
            <VSpacing default={4} />
        </CollapsibleContainer>
    );
};

export default EmployerBalanceWidget;
