import { Card, Text, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import NewsOrArticlesContent from 'src/components/IndexPageEmployer/components/Blog/NewsOrArticlesContent';

interface NewsOrArticlesCardProps {
    isArticle?: boolean;
}

const TrlKeys = {
    news: 'index.blog.news.title',
    articles: 'index.blog.articles.title',
};

const NewsOrArticlesCard: TranslatedComponent<NewsOrArticlesCardProps> = ({ isArticle, trls }) => {
    const employerNews = useSelector((state) => state.employerNews);
    const employerArticles = useSelector((state) => state.employerArticles);

    const props = isArticle
        ? {
              href: '/articles/employers',
              items: employerArticles,
          }
        : {
              href: '/articles/site-news',
              items: employerNews,
          };
    return (
        <Card borderWidth="default" padding={24} borderRadius={24} stretched verticalStretched>
            <Text typography="title-5-semibold">{trls[isArticle ? TrlKeys.articles : TrlKeys.news]}</Text>
            <VSpacing default={24} />
            <div>
                <NewsOrArticlesContent isArticle={isArticle} {...props} />
            </div>
        </Card>
    );
};

export default translation(NewsOrArticlesCard);
