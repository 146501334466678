import { MutableRefObject, useEffect, useState } from 'react';

import { useBreakpoint } from '@hh.ru/magritte-ui';

const isTextClamped = (element: HTMLElement) => element.scrollHeight > element.clientHeight;

export function useIsTextClamped(elementRef: MutableRefObject<HTMLElement | null>): boolean {
    const [isClamped, setIsClamped] = useState(false);
    const { breakpoint } = useBreakpoint();
    useEffect(() => {
        if (elementRef.current) {
            setIsClamped(isTextClamped(elementRef.current));
        }
    }, [breakpoint, elementRef]);
    return isClamped;
}
