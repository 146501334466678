import { Button, Text } from '@hh.ru/magritte-ui';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import AdviceCard from 'src/components/EmployerAdvicesWidget/AdviceCard';
import { useHandleAnalyticsCardClick } from 'src/components/EmployerAdvicesWidget/useHandleAnalyticsCardClick';
import LowActivityAdviceModal from 'src/components/EmployerVacancies/Advices/LowActivityAdviceModal';
import translation from 'src/components/translation';
import { useOnOffState } from 'src/hooks/useOnOffState';
import { LowActivityVacanciesAdvice } from 'src/models/employerAdvicesWidget/advices';

const TrlKeys = {
    text: 'employer.index.advicesWidget.lowActivityVacancies.text',
    highlighted: 'employer.index.advicesWidget.lowActivityVacancies.highlighted',
    button: 'employer.index.advicesWidget.lowActivityVacancies.button',
    vacancies: {
        one: 'employer.index.advicesWidget.lowActivityVacancies.one',
        some: 'employer.index.advicesWidget.lowActivityVacancies.some',
        many: 'employer.index.advicesWidget.lowActivityVacancies.many',
    },
    get: {
        one: 'employer.index.advicesWidget.lowActivityVacancies.get.one',
        some: 'employer.index.advicesWidget.lowActivityVacancies.get.some',
        many: 'employer.index.advicesWidget.lowActivityVacancies.get.many',
    },
};

const LowActivityVacancies: TranslatedComponent<LowActivityVacanciesAdvice> = ({ trls, name, data }) => {
    const [isModalOpen, showModal, hideModal] = useOnOffState(false);
    const sendAnalytics = useHandleAnalyticsCardClick(name);

    const handleClick = () => {
        sendAnalytics();
        showModal();
    };
    return (
        <>
            <AdviceCard
                name={name}
                button={
                    <Button onClick={handleClick} size="small" stretched mode="secondary" style="neutral">
                        {trls[TrlKeys.button]}
                    </Button>
                }
            >
                {formatToReactComponent(trls[TrlKeys.text], {
                    '{highlighted}': (
                        <Text typography="title-5-semibold" Element="span" style="special">
                            {trls[TrlKeys.highlighted]}
                        </Text>
                    ),
                    '{count}': (
                        <Text typography="title-5-semibold" Element="span" style="special">
                            <Conversion
                                one={trls[TrlKeys.vacancies.one]}
                                some={trls[TrlKeys.vacancies.some]}
                                many={trls[TrlKeys.vacancies.many]}
                                value={data.vacancies.length}
                                hasValue
                            />
                        </Text>
                    ),
                    '{get}': (
                        <Conversion
                            one={trls[TrlKeys.get.one]}
                            some={trls[TrlKeys.get.some]}
                            many={trls[TrlKeys.get.many]}
                            value={data.vacancies.length}
                            hasValue={false}
                        />
                    ),
                })}
            </AdviceCard>
            <LowActivityAdviceModal hideModal={hideModal} isModalOpen={isModalOpen} vacancies={data.vacancies} />
        </>
    );
};

export default translation(LowActivityVacancies);
