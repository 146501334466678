import buttonClick from '@hh.ru/analytics-js-events/build/xhh/common/main/button_click';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import Modal, { ModalHeader, ModalTitle } from 'bloko/blocks/modal';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useToggleState } from 'src/hooks/useToggleState';
import {
    ProfessionalRoleCategory,
    ProfessionalRoleCategoryCounted,
} from 'src/models/professionalRoleCategoryCollection';

import Header from 'src/components/WorkInProfession/Header';
import ListItem from 'src/components/WorkInProfession/ListItemMobile';

import styles from './modal-group-mobile.less';

const TrlKeys = {
    showAll: 'industriesCatalog.viewAll',
};

interface MobileModalProps {
    title: string;
    collection: ProfessionalRoleCategory[] | ProfessionalRoleCategoryCounted[];
}

const ModalGroupMobile: TranslatedComponent<MobileModalProps> = ({ title, collection, trls }) => {
    const [showModal, toggleShowModal] = useToggleState(false);
    const handleClickShowAll = () => {
        toggleShowModal();
        buttonClick({ buttonName: 'vacancy_professional_catalog_list' });
    };

    return (
        <>
            <BlokoLink
                appearance={LinkAppearance.Pseudo}
                onClick={handleClickShowAll}
                data-qa="industries-catalog-trigger"
            >
                {trls[TrlKeys.showAll]}
            </BlokoLink>
            <Modal visible={showModal} onClose={toggleShowModal}>
                <ModalHeader outlined>
                    <ModalTitle>
                        <Header text={title} />
                        <VSpacing base={4} />
                    </ModalTitle>
                </ModalHeader>
                <div className={styles.modal}>
                    <ul className={styles.modalContent} data-qa="index__work-in-profession-modal-list">
                        {collection.map((category, index) => (
                            <ListItem
                                key={category.id}
                                category={category}
                                positionIndex={index}
                                isLast={index === collection.length - 1}
                                isModal
                            />
                        ))}
                    </ul>
                </div>
            </Modal>
        </>
    );
};

export default translation(ModalGroupMobile);
