import { FC, useEffect, useRef } from 'react';

import buttonClick from '@hh.ru/analytics-js-events/build/xhh/common/main/button_click';
import { Avatar, Cell, CellText, VSpacingContainer, VSpacing } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import newsBoxAnalytics from 'src/components/NewsBox/analytics';
import { useFeatureEnabled } from 'src/hooks/useFeatureEnabled';
import { ArticleItemWithImage } from 'src/models/article.types';

import ShowAllLink from 'src/components/IndexPageEmployer/components/Blog/ShowAllLink';

import styles from './styles.less';

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

interface NewsOrArticlesContentProps {
    isArticle?: boolean;
    items: ArticleItemWithImage[] | null;
    href: string;
}

const MAXIMUM_ITEMS_COUNT = 3;

const NewsOrArticlesContent: FC<NewsOrArticlesContentProps> = ({ href, isArticle, items }) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const newsBoxRef = useRef(null);
    const newsBoxItems = (items || []).slice(0, MAXIMUM_ITEMS_COUNT);
    const newsBoxType = isArticle ? 'article' : 'news';

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled && newsBoxItems?.length && newsBoxRef.current) {
            const { stopSpying } = newsBoxAnalytics.sendNewsBoxItemsShown(
                newsBoxRef.current,
                newsBoxType,
                newsBoxItems.map((item) => item.title)
            );

            return stopSpying;
        }

        return undefined;
    }, [employerMainPageAnalyticsEnabled, isArticle, newsBoxItems, newsBoxType]);

    const handleClick = () => {
        if (employerMainPageAnalyticsEnabled) {
            newsBoxAnalytics.sendNewsBoxTitleClick(newsBoxType);
        }
        buttonClick({ buttonName: isArticle ? 'article_list' : 'news_list' });
    };

    const dataQaName = isArticle ? 'articles' : 'news';

    return (
        <div data-qa={`${dataQaName}-content`} className={styles.content} ref={newsBoxRef}>
            <VSpacingContainer default={24}>
                {newsBoxItems.map(({ title, href, image }) => {
                    return (
                        <SPALink
                            data-qa={`${dataQaName}-link`}
                            to={href}
                            key={title}
                            onClick={() => buttonClick({ buttonName: isArticle ? 'article_item' : 'news_item' })}
                        >
                            <Cell
                                avatar={
                                    <Avatar
                                        mode="image"
                                        size={48}
                                        aria-label={title}
                                        Element="div"
                                        image={image}
                                        placeholder="city"
                                        fallbackMode="placeholder"
                                    />
                                }
                            >
                                <CellText maxLines={2}>{title}</CellText>
                            </Cell>
                        </SPALink>
                    );
                })}
            </VSpacingContainer>

            <VSpacing default={24} />

            <div className={styles.spacer} />

            <ShowAllLink data-qa={`${dataQaName}-show-more-link`} href={href} onClick={handleClick} />
        </div>
    );
};

export default NewsOrArticlesContent;
