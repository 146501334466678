import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import { Cell, CellText, Text } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24, CrossOutlinedSize24, IconDynamic } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import Area from 'src/components/EmployerResumeSearchesWidget/components/CellSubtitle/Area';
import Employment from 'src/components/EmployerResumeSearchesWidget/components/CellSubtitle/Employment';
import Subtitle, { SubtitleItem } from 'src/components/EmployerResumeSearchesWidget/components/CellSubtitle/Subtitle';
import CellWrapper from 'src/components/EmployerResumeSearchesWidget/components/CellWrapper';
import Placeholder from 'src/components/EmployerResumeSearchesWidget/components/Placeholder';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { removeSavedSearch, SavedSearch } from 'src/models/employer/employerLastSearches';
import { CriteriaKey } from 'src/types/search/common/criteria';
import { fetcher } from 'src/utils/fetcher';
import { deleteDeprecatedFilter } from 'src/utils/resumeSearch/deleteDeprecatedFilter';

import styles from './styles.less';

const TrlKeys = {
    noName: 'resumeSearchesWidget.lastSearches.noName',
    allSearches: 'resumeSearchesWidget.lastSearches.allSearches',
    remove: 'resumeSearchesWidget.lastSearches.remove',
    placeholder: {
        description: 'resumeSearchesWidget.lastSearches.placeholder.description',
    },
};

const LastSearches: TranslatedComponent = ({ trls }) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const items = useSelector((state) => state.employerLastSearches);

    const handleLinkClick = useCallback(() => {
        Analytics.sendHHEventButtonClick('last_searches_item', { type: 'search' });
    }, []);

    const handleRemove = async (id: string) => {
        Analytics.sendHHEventButtonClick('last_searches_item', { type: 'remove' });
        dispatch(removeSavedSearch({ id }));
        try {
            await fetcher.postFormData('/shards/employer/last_search/remove', { 'search-id': id });
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
        }
    };

    const getCellSubtitle = (savedSearch: SavedSearch): SubtitleItem[] => {
        const { searchCriteria } = savedSearch;
        const areas = searchCriteria?.[CriteriaKey.Area];
        const employments = searchCriteria?.[CriteriaKey.Employment];
        const subtitleItems: SubtitleItem[] = [{ content: <Area areas={areas?.map((area) => area.text)} /> }];

        if (employments?.length) {
            subtitleItems.push({
                growing: true,
                content: <Employment employments={employments.map((employment) => employment.text)} />,
            });
        }

        return subtitleItems;
    };

    if (!items.length) {
        return (
            <Placeholder>
                <Text typography="paragraph-2-regular" style="secondary">
                    {trls[TrlKeys.placeholder.description]}
                </Text>
            </Placeholder>
        );
    }

    return (
        <>
            {items.map((item, index) => {
                const { id, text, searchCriteria, searchQuery } = item;

                return (
                    <CellWrapper key={id}>
                        <SPALink
                            to={`/search/resume?${deleteDeprecatedFilter(searchQuery)}&mark=main_page_last_search_${index + 1}`}
                            onClick={handleLinkClick}
                        >
                            <div className={styles.cellContent}>
                                <Cell
                                    right={
                                        <div>
                                            <div className={styles.chevron}>
                                                <ChevronRightOutlinedSize24 initialColor="secondary" />
                                            </div>

                                            <div className={styles.remove}>
                                                <IconDynamic>
                                                    <button
                                                        onClick={async (event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            await handleRemove(id);
                                                        }}
                                                    >
                                                        <CrossOutlinedSize24
                                                            initialColor="secondary"
                                                            highlightedColor="primary"
                                                        />
                                                    </button>
                                                </IconDynamic>
                                            </div>
                                        </div>
                                    }
                                >
                                    <CellText maxLines={1}>{text || trls[TrlKeys.noName]}</CellText>

                                    {searchCriteria && (
                                        <CellText type="subtitle" maxLines={1}>
                                            <Subtitle items={getCellSubtitle(item)} />
                                        </CellText>
                                    )}
                                </Cell>
                            </div>
                        </SPALink>
                    </CellWrapper>
                );
            })}
        </>
    );
};

export default translation(LastSearches);
