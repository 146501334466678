import { useRef } from 'react';

import Analytics, { ElementShownAnchor } from '@hh.ru/analytics-js';
import { Text, TooltipHover } from '@hh.ru/magritte-ui';
import { ExclamationTriangleFilledSize24, GridOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import BalanceCard from 'src/components/EmployerBalanceWidget/BalanceCard';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    title: 'employer.index.balanceWidget.services.title',
    zpTitle: 'employer.index.balanceWidget.zp.services.title',
    waitForActivation: 'employer.index.balanceWidget.services.waitForActivation',
    waitForPayment: 'employer.index.balanceWidget.services.waitForPayment',
    hasActive: 'employer.index.balanceWidget.services.hasActive',
    notActive: 'employer.index.balanceWidget.services.notActive',
    tooltip: 'employer.index.balanceWidget.services.waitForPayment.tooltip',
};

const ServicesCard: TranslatedComponent = ({ trls }) => {
    const isZp = useIsZarplataPlatform();
    const activatorRef = useRef(null);
    const employerIndexPage = useSelector(({ employerIndexPage }) => employerIndexPage);

    const services = employerIndexPage?.oldEmployer?.services;
    const waitForActivation = !!services?.waitForActivationCount;
    const waitForPayment = !!services?.waitForPaymentCount;
    const hasActive = !!employerIndexPage.oldEmployer?.employer.serviceDescription?.length;

    const isWarning = waitForPayment || waitForActivation;

    let textKey = TrlKeys.notActive;
    let linkTo = '';
    let isEmpty = true;

    if (waitForActivation) {
        textKey = TrlKeys.waitForActivation;
        linkTo = paths.employerCarts;
        isEmpty = false;
    } else if (waitForPayment) {
        textKey = TrlKeys.waitForPayment;
        linkTo = paths.employerUnpaidCarts;
        isEmpty = false;
    } else if (hasActive) {
        textKey = TrlKeys.hasActive;
        linkTo = paths.accountHistoryServices;
        isEmpty = false;
    }

    const analyticParams = {
        card: 'services_balance',
        waitForActivation,
        waitForPayment,
        hasActive,
    };

    const handleClick = () => {
        const event = isEmpty ? 'balance_widget_card_plus_click' : 'balance_widget_card_click';

        Analytics.sendHHEventButtonClick(event, analyticParams);
    };

    const card = (
        <BalanceCard
            addUrl={isEmpty ? paths.priceBranding : undefined}
            type="default"
            isWarning={isWarning}
            onPlusClick={handleClick}
            rightContent={
                isWarning ? (
                    <ExclamationTriangleFilledSize24 initialColor="contrast" />
                ) : (
                    <GridOutlinedSize24 initialColor="secondary" />
                )
            }
            title={trls[isZp ? TrlKeys.zpTitle : TrlKeys.title]}
        >
            <Text style={isWarning ? 'contrast' : undefined} typography="subtitle-1-semibold">
                {trls[textKey]}
            </Text>
        </BalanceCard>
    );

    return (
        <ElementShownAnchor
            fn={Analytics.sendHHEventElementShown}
            name="balance_widget_card"
            card="services_balance"
            waitForActivation={waitForActivation}
            waitForPayment={waitForPayment}
            hasActive={hasActive}
        >
            {isEmpty ? (
                card
            ) : (
                <SPALink onClick={handleClick} ref={activatorRef} to={linkTo}>
                    {card}
                    {waitForPayment && !waitForActivation ? (
                        <TooltipHover size="medium" activatorRef={activatorRef} placement="right-center">
                            {trls[TrlKeys.tooltip]}
                        </TooltipHover>
                    ) : null}
                </SPALink>
            )}
        </ElementShownAnchor>
    );
};

export default translation(ServicesCard);
