import Analytics from '@hh.ru/analytics-js';
import { usePush } from '@hh.ru/redux-spa-middleware';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { loginBy } from 'src/components/ApplicantSignInSignUpFlow/SignInStep/SignInForm';
import { useNotification } from 'src/components/Notifications/Provider';
import { useSignupAnalyticsContext } from 'src/components/SignupModal/contexts/SignupAnalytics';
import DefaultVerification from 'src/components/Verification/Verification';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { OtpOperationType } from 'src/models/otp';
import { formatAnalyticErrors } from 'src/utils/analytics/formatAnalyticErrors';
import { fetcher } from 'src/utils/fetcher';

const VerificationFormStep = ({ Verification = DefaultVerification, isSignupPage, next, onSignIn, ...props }) => {
    const analitics = useSignupAnalyticsContext();
    const push = usePush();
    const { login, authType, accountType, isMultiAccount } = useSelector(({ otp }) => otp);
    const vacancyId = useSelector(({ postponedActions }) => postponedActions?.vacancy?.vacancyId);
    const { addNotification } = useNotification();
    const operationType =
        accountType === 'EMPLOYER' ? OtpOperationType.EmployerOtpAuth : OtpOperationType.ApplicantOtpAuth;

    const onShownAnalytics = (elementRef) => {
        if (elementRef.current) {
            Analytics.sendHHEventElementShown(elementRef.current, {
                elementName: 'code_confirmation',
                vacancyId,
                authType: loginBy[authType] || 'unknown',
                isSignupPage,
                accountType,
                isMultiAccount,
                hhtmSource: analitics.hhtmSource,
            });
        }
    };

    const onSubmitAnalytics = (errorCode) => {
        Analytics.sendHHEvent('form_submit', {
            formName: 'code_confirmation',
            vacancyId,
            authType: loginBy[authType] || 'unknown',
            errors: errorCode ? formatAnalyticErrors('code_verification', errorCode) : null,
            isSignupPage,
            accountType,
            isMultiAccount,
            hhtmSource: analitics.hhtmSource,
        });
    };

    const onSubmit = async (setIsSending, setVerification, code) => {
        let response;
        setIsSending(true);

        try {
            response = await fetcher.postFormData('/account/login/by_code', {
                username: login,
                code,
                remember: true,
                accountType: isSignupPage ? 'APPLICANT' : undefined,
                operationType: 'otp_auth',
            });
        } catch (error) {
            setIsSending(false);
            const errorCode = error?.response?.data?.error?.key;
            const isMultiAccountCreation = error?.response?.data?.isMultiAccountCreation;

            if (errorCode === 'EMPLOYER_NOT_ALLOWED' && isMultiAccountCreation) {
                try {
                    await fetcher.post('/multiaccount_grouping/reset_employer_password', {
                        code,
                        operationType: 'OTP_GENERATE',
                    });
                } catch {
                    onSubmitAnalytics(errorCode);
                    push(error.response.data.employerLoginURL);
                    return;
                }

                onSubmitAnalytics(errorCode);
                next(code, isMultiAccountCreation);
                return;
            }

            if (errorCode === 'EMPLOYER_NOT_ALLOWED' || errorCode === 'UNEXPECTED_USER_TYPE') {
                push(error.response.data.employerLoginURL);
                onSubmitAnalytics(errorCode);
                return;
            }

            if (errorCode === 'UNEXPECTED_USER_TYPE' || errorCode === 'ANONYMOUS_ONLY') {
                push(error.response.data.loginURL);
                onSubmitAnalytics(errorCode);
                return;
            }

            if (errorCode === 'ACCOUNT_NOT_FOUND') {
                onSubmitAnalytics();
                next(code, isMultiAccountCreation);
                return;
            }

            if (errorCode === 'ACCOUNT_BLOCKED') {
                setVerification({ key: errorCode, success: false });
                onSubmitAnalytics(errorCode);
                return;
            }

            onSubmitAnalytics('SERVER_ERROR');
            defaultRequestErrorHandler(error, addNotification);
            return;
        }

        const errorCode = response?.data?.success ? null : response?.data?.verification?.key;
        onSubmitAnalytics(errorCode);
        setIsSending(false);

        if (response?.data?.success) {
            onSignIn?.();
            return;
        }

        setVerification(response?.data?.verification);
    };

    return (
        <Verification
            onShownAnalytics={onShownAnalytics}
            authType={authType}
            login={login}
            onSubmit={onSubmit}
            operationType={operationType}
            {...props}
        />
    );
};

export default translation(VerificationFormStep);
