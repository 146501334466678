import { ReactElement, ReactNode } from 'react';

import Analytics, { ElementShownAnchor } from '@hh.ru/analytics-js';
import { Card, Cell, Text, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './styles.less';

interface ServiceCardProps {
    title: string;
    name: string;
    children: ReactNode;
    icon: ReactElement;
    isActive: boolean;
}

const ServiceCard: TranslatedComponent<ServiceCardProps> = ({ children, title, icon, isActive, name }) => {
    return (
        <ElementShownAnchor
            fn={Analytics.sendHHEventElementShown}
            name="services_widget_card"
            card={name}
            active={isActive}
        >
            <div className={styles.container}>
                <Card
                    style={isActive ? undefined : 'secondary'}
                    className={styles.card}
                    stretched
                    verticalStretched
                    borderRadius={24}
                    borderWidth={isActive ? 'default' : 'none'}
                    padding={24}
                >
                    <Text typography="label-3-regular" style="secondary">
                        {title}
                    </Text>
                    <VSpacing default={8} />
                    <Cell right={icon}>{children}</Cell>
                </Card>
            </div>
        </ElementShownAnchor>
    );
};

export default translation(ServiceCard);
