import { useEffect, useMemo, useRef, useState } from 'react';

import {
    Action,
    ActionBar,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Modal,
    NavigationBar,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { fetcher } from 'src/utils/fetcher';

import DriverLicense from 'src/components/ResumeAdditionalDataCollector/DriverLicense';
import { type ProfileUpdatePayload, updateProfile } from 'src/components/ResumeAdditionalDataCollector/api';
import { type DriverLicenses } from 'src/components/ResumeAdditionalDataCollector/types';
import { getCheckedDriverLicenseTypes } from 'src/components/ResumeAdditionalDataCollector/utils';

const TrlKeys = {
    driverLicenseTitle: 'resumeAdditionalDataCollector.driverLicenseTitle',
    save: 'resumeAdditionalDataCollector.save',
};

const ResumeAdditionalDataCollector: TranslatedComponent = ({ trls }) => {
    const { isMobile } = useBreakpoint();
    const abortControllerRef = useRef<AbortController | null>(null);

    const resumeAdditionalData = useSelector((state) => state.resumeAdditionalData);
    const resumeHash = resumeAdditionalData?.resumeHash ?? '';
    const isDriverLicenseCollecting = useMemo(() => {
        return resumeAdditionalData?.requiredAdditionalData?.includes('DRIVING_LICENSE') ?? false;
    }, [resumeAdditionalData]);

    const [hasVehicle, setHasVehicle] = useState(false);
    const [driverLicenses, setDriverLicenses] = useState<DriverLicenses>({});
    const [isClosed, setIsClosed] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const isVisible = Boolean(resumeHash) && isDriverLicenseCollecting && !isClosed;

    const handleClose = () => {
        abortControllerRef.current?.abort();
        setIsClosed(true);
    };

    const handleSave = async () => {
        setIsSaving(true);

        try {
            abortControllerRef.current?.abort();
            const abortController = new AbortController();
            abortControllerRef.current = abortController;

            const payload: ProfileUpdatePayload = {
                profile: {
                    hasVehicle,
                    driverLicenseTypes: getCheckedDriverLicenseTypes(driverLicenses),
                },
            };

            await updateProfile(payload, abortController.signal);
            setIsClosed(true);
        } catch (error) {
            if (!fetcher.isCancel(error)) {
                console.error(error);
            }
        } finally {
            setIsSaving(false);
        }
    };

    useEffect(() => {
        return () => {
            abortControllerRef.current?.abort();
        };
    }, []);

    const titleTrl = trls[TrlKeys.driverLicenseTitle];
    const content = (
        <DriverLicense
            isSaving={isSaving}
            hasVehicle={hasVehicle}
            setHasVehicle={setHasVehicle}
            driverLicenses={driverLicenses}
            setDriverLicenses={setDriverLicenses}
        />
    );

    const saveButton = (
        <Button mode="primary" style="accent" onClick={handleSave} loading={isSaving}>
            {trls[TrlKeys.save]}
        </Button>
    );

    if (isMobile) {
        return (
            <BottomSheet
                visible={isVisible}
                onClose={handleClose}
                height="full-screen"
                header={
                    <NavigationBar
                        title={titleTrl}
                        right={<Action icon={CrossOutlinedSize24} onClick={handleClose} />}
                    />
                }
                footer={<BottomSheetFooter>{saveButton}</BottomSheetFooter>}
            >
                {content}
            </BottomSheet>
        );
    }

    return (
        <Modal
            visible={isVisible}
            onClose={handleClose}
            title={titleTrl}
            actions={<Action style="neutral" mode="secondary" icon={CrossOutlinedSize24} onClick={handleClose} />}
            footer={<ActionBar type="modal" primaryActions={saveButton} />}
        >
            {content}
        </Modal>
    );
};

export default translation(ResumeAdditionalDataCollector);
