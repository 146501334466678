import { FC, MutableRefObject } from 'react';

import LastSearches from 'src/components/EmployerResumeSearchesWidget/components/LastSearches';
import Resumes from 'src/components/EmployerResumeSearchesWidget/components/Resumes';
import SavedSearches from 'src/components/EmployerResumeSearchesWidget/components/SavedSearches';
import { useSelector } from 'src/hooks/useSelector';
import { ResumeSearchesWidgetTabName } from 'src/models/employer/resumeSearchesWidget/types';

interface TabsContentProps {
    activeTab: ResumeSearchesWidgetTabName;
    containerRef: MutableRefObject<HTMLDivElement | null>;
}

const TabsContent: FC<TabsContentProps> = ({ activeTab, containerRef }) => {
    const { favoriteResumes, targetResumes } = useSelector((state) => state.resumeSearchesWidget);
    const hasNewResumes = targetResumes.items.some((resume) => resume.isNew);

    switch (activeTab) {
        case 'targetResumes':
            return (
                <Resumes
                    items={targetResumes.items}
                    allResumesLink="/employer/targetresumes"
                    analyticsName="target_resumes"
                    hasNewResumes={hasNewResumes}
                    containerRef={containerRef}
                />
            );
        case 'favoriteResumes':
            return (
                <Resumes
                    items={favoriteResumes.items}
                    allResumesLink="/employer/resumefolders"
                    analyticsName="favorite_resumes"
                    containerRef={containerRef}
                    isFavoriteResumes
                />
            );
        case 'savedSearches':
            return <SavedSearches containerRef={containerRef} />;
        case 'lastSearches':
            return <LastSearches />;
        default:
            return null;
    }
};

export default TabsContent;
