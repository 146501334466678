import { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './styles.less';

interface CellWrapperProps {
    isShort?: boolean;
    children: ReactNode;
}

const CellWrapper: FC<CellWrapperProps> = ({ children, isShort }) => {
    return <div className={cn(styles.cellWrapper, { [styles.cellWrapperShort]: isShort })}>{children}</div>;
};

export default CellWrapper;
