import { Fragment } from 'react';

import { VSpacing, Text, Divider } from '@hh.ru/magritte-ui';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import getTranslationsFor from 'src/components/IndexPageEmployer/components/EmployerEventList/getTranslationsFor';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ServiceEmployerDescription } from 'src/models/oldEmployer/employerBillingSummary';
import { ServiceCode } from 'src/models/price/productCodes';

import { isItemExpireSoon } from 'src/components/EmployerBalanceWidget/cards/ResumeAccessCard/isItemExpireSoon';
import { useGetPublicationItems } from 'src/components/EmployerBalanceWidget/cards/ResumeAccessCard/useGetResumeAccessItems';

import styles from './styles.less';

const TrlKeys = {
    buy: 'index.employer.billing.buy',
    resumeAccess: 'index.employer.billing.resumeAccess',
    [ServiceCode.Reservation]: 'employer.accountHistory.availableServices.RESERVATION.CIV',
    [ServiceCode.Add]: 'employer.accountHistory.availableServices.ADD.CIV',
    areaAll: 'employer.price.area.all',
    allProfRoleGroups: 'billing_professional_role_group.0',
    balanceEmpty: 'employer.service.balance.empty',
    serviceBalance: 'employer.index.balanceWidget.service.balance',
    expiresSoon: 'index.employer.billing.expiresSoon',
    expires: 'index.employer.billing.expires',
    expiresTime: 'employer.index.balanceWidget.resumeAccess.expires.time',
    activeTo: 'price.already-purchased.active-to',
    activeSins: 'price.already-purchased.active-sins',
    contactOpening: {
        some: 'employer.service.contact.opening.some',
        one: 'employer.service.contact.opening.one',
        many: 'employer.service.contact.opening.many',
    },
    genitive: {
        month: {
            1: 'genitive.month.1',
            2: 'genitive.month.2',
            3: 'genitive.month.3',
            4: 'genitive.month.4',
            5: 'genitive.month.5',
            6: 'genitive.month.6',
            7: 'genitive.month.7',
            8: 'genitive.month.8',
            9: 'genitive.month.9',
            10: 'genitive.month.10',
            11: 'genitive.month.11',
            12: 'genitive.month.12',
        },
    },
    days: {
        one: 'index.employer.billing.days.one',
        some: 'index.employer.billing.days.some',
        many: 'index.employer.billing.days.many',
    },
    access: 'index.employer.billing.access.common',
};

const ResumeAccessHoverContent: TranslatedComponent = ({ trls }) => {
    const employerIndexPage = useSelector(({ employerIndexPage }) => employerIndexPage);
    const resumeAccessItems = useGetPublicationItems();

    const getPurchasedAccessCivBalance = (item: ServiceEmployerDescription) => {
        const { civBalance } = item;

        if (civBalance === undefined || civBalance === null) {
            return null;
        }

        let content;

        if (civBalance === 0) {
            content = format(trls[TrlKeys.balanceEmpty], {
                '{0}': trls[TrlKeys.contactOpening.some],
            });
        } else {
            content = (
                <Conversion
                    one={trls[TrlKeys.contactOpening.one]}
                    some={trls[TrlKeys.contactOpening.some]}
                    many={trls[TrlKeys.contactOpening.many]}
                    value={civBalance}
                    hasValue={false}
                    format={(trl) =>
                        format(trls[TrlKeys.serviceBalance], {
                            '{0}': civBalance,
                            '{1}': trl,
                        })
                    }
                />
            );
        }

        return (
            <Text typography="label-3-regular" style="secondary">
                {content}
            </Text>
        );
    };

    const getActiveDate = (item: ServiceEmployerDescription) => {
        const { expirationTime } = item;

        const isExpireSoon = isItemExpireSoon(item);
        return (
            <Text typography="label-3-regular" style={isExpireSoon ? 'negative' : 'secondary'}>
                {trls[TrlKeys.activeTo]} {expirationTime.day} {trls[TrlKeys.genitive.month[expirationTime.month]]}{' '}
                {expirationTime.year} {trls[TrlKeys.expiresTime]} {expirationTime.hour}:
                {expirationTime.minute.toString().padStart(2, '0')}
            </Text>
        );
    };

    const getFutureDate = (
        expirationTime: ServiceEmployerDescription['expirationTime'],
        activationTime: ServiceEmployerDescription['activationTime']
    ) => {
        return (
            <>
                <Text typography="label-3-regular" style="secondary">
                    {format(trls[TrlKeys.activeSins], {
                        '{0}': activationTime.day,
                        '{1}': trls[TrlKeys.genitive.month[activationTime.month]],
                        '{2}': activationTime.year,
                    })}
                </Text>
                <Text typography="label-3-regular" style="secondary">
                    {expirationTime &&
                        `${trls[TrlKeys.activeTo]} ${expirationTime.day} ${
                            trls[TrlKeys.genitive.month[expirationTime.month]]
                        } ${expirationTime.year}`}
                </Text>
            </>
        );
    };

    const purchasedServicesDate = (item: ServiceEmployerDescription) => {
        const { status, expirationTime, activationTime } = item;

        if (status === 'active') {
            return getActiveDate(item);
        }

        if (status === 'future') {
            return getFutureDate(expirationTime, activationTime);
        }

        return null;
    };

    const getPurchasedResumeAccessesItem = (item: ServiceEmployerDescription) => {
        return (
            <div data-qa={`purchased-service-${item.code}`} className={styles.item}>
                <Text typography="subtitle-1-semibold">{item.availableServicesTrl}</Text>
                <VSpacing default={8} />
                <Text typography="label-2-regular">{getTranslationsFor(item.priceAreaTrl, trls[TrlKeys.areaAll])}</Text>
                <Text typography="label-2-regular">
                    {getTranslationsFor(
                        item.profRoleGroupIdsTrl ? item.profRoleGroupIdsTrl : item.professionIdsTrl,
                        trls[TrlKeys.allProfRoleGroups]
                    )}
                </Text>
                <VSpacing default={8} />
                {getPurchasedAccessCivBalance(item)}
                {purchasedServicesDate(item)}
            </div>
        );
    };

    if (employerIndexPage.oldEmployer) {
        return (
            <div className={styles.container}>
                {resumeAccessItems.map((service, index) => (
                    <Fragment key={index}>
                        {index !== 0 && <Divider marginTop={24} marginBottom={24} />}
                        {getPurchasedResumeAccessesItem(service)}
                    </Fragment>
                ))}
            </div>
        );
    }

    return null;
};

export default translation(ResumeAccessHoverContent);
