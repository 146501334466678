import { useExperiment } from '@hh.ru/front-static-app';

import Layout from 'src/components/IndexPageEmployer/components/Layout';
import LayoutRedesign from 'src/components/IndexPageEmployer/components/LayoutRedesign';

const IndexEmployer = () => {
    const isMainPageRedesign = useExperiment('main_page_redesign_v1');

    return isMainPageRedesign ? <LayoutRedesign /> : <Layout />;
};

export default IndexEmployer;
