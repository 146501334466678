// tempexp_28466_file
import { AxiosResponse } from 'axios';

import { fetcher } from 'src/utils/fetcher';

declare global {
    interface FetcherPostApi {
        '/shards/anonymous/set_signup_backurl': {
            body: { backUrl?: string };
            response: void;
            queryParams: void;
        };
        '/shards/anonymous/signup_modal_already_shown': {
            body: void;
            response: void;
            queryParams: void;
        };
    }
}

export const postSignupBackurl = (backUrl: string): Promise<AxiosResponse<void>> =>
    fetcher.post('/shards/anonymous/set_signup_backurl', { backUrl });

export const postSignupModalAlreadyShown = (): Promise<AxiosResponse<void>> =>
    fetcher.post('/shards/anonymous/signup_modal_already_shown');
