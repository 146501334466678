import lightFormat from 'date-fns/lightFormat';
import subDays from 'date-fns/subDays';

import { Button, Text } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import AdviceCard from 'src/components/EmployerAdvicesWidget/AdviceCard';
import { useHandleAnalyticsCardClick } from 'src/components/EmployerAdvicesWidget/useHandleAnalyticsCardClick';
import translation from 'src/components/translation';
import { LowSalaryAdvice } from 'src/models/employerAdvicesWidget/advices';

const TrlKeys = {
    text: 'employer.index.advicesWidget.lowSalary.text',
    highlighted: 'employer.index.advicesWidget.lowSalary.highlighted',
    count: {
        one: 'employer.index.advicesWidget.lowSalary.count.one',
        some: 'employer.index.advicesWidget.lowSalary.count.some',
        many: 'employer.index.advicesWidget.lowSalary.count.many',
    },
    button: 'employer.index.advicesWidget.lowSalary.button',
};

const getHref = () => {
    const DATE_FORMAT_STRING = 'yyyy-MM-dd';
    const startDate = lightFormat(subDays(new Date(), 1), DATE_FORMAT_STRING);
    const endDate = lightFormat(new Date(), DATE_FORMAT_STRING);

    return (
        `/employer/statistics/vacancies?from=main_advices` +
        `&startDate=${startDate}` +
        `&endDate=${endDate}` +
        '&salaryRange=LOW&salaryRange=TOO_LOW'
    );
};

const LowSalary: TranslatedComponent<LowSalaryAdvice> = ({ trls, name, data: { vacancyCount } }) => {
    const sendAnalytics = useHandleAnalyticsCardClick(name);

    return (
        <AdviceCard
            name={name}
            button={
                <Button
                    onClick={sendAnalytics}
                    Element={SPALink}
                    to={getHref()}
                    size="small"
                    stretched
                    mode="secondary"
                    style="neutral"
                >
                    {trls[TrlKeys.button]}
                </Button>
            }
        >
            {formatToReactComponent(trls[TrlKeys.text], {
                '{count}': (
                    <Conversion
                        one={trls[TrlKeys.count.one]}
                        some={trls[TrlKeys.count.some]}
                        many={trls[TrlKeys.count.many]}
                        value={vacancyCount}
                        hasValue
                    />
                ),
                '{highlighted}': (
                    <Text typography="title-5-semibold" Element="span" style="negative">
                        {trls[TrlKeys.highlighted]}
                    </Text>
                ),
            })}
        </AdviceCard>
    );
};

export default translation(LowSalary);
