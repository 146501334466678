import { Link, Text } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ServiceCard from 'src/components/EmployerServicesWidget/ServiceCard';
import ServiceCardIcon from 'src/components/EmployerServicesWidget/ServiceCardIcon';
import { useHandleAnalyticsCardClick } from 'src/components/EmployerServicesWidget/useHandleAnalyticsCardClick';
import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { EmployerServiceWidget } from 'src/models/employerServicesWidget';

const TrlKeys = {
    title: 'employer.index.servicesWidget.clickme.title',
    connectTitle: 'employer.index.servicesWidget.clickme.connect.title',
    connect: 'employer.index.servicesWidget.clickme.connect',
    cabinets: {
        one: 'employer.index.servicesWidget.clickme.cabinet.one',
        some: 'employer.index.servicesWidget.clickme.cabinet.some',
        many: 'employer.index.servicesWidget.clickme.cabinet.many',
    },
};

const CLICKME_CONNECTED_LINK =
    'https://clickme.hh.ru/new/dashboard?utm_source=hh.ru&utm_medium=referral&utm_campaign=main_widget_connected_service';
const CLICKME_NOT_CONNECTED_LINK =
    'https://clickme.hh.ru/new/dashboard?utm_source=hh.ru&utm_medium=referral&utm_campaign=main_widget_unconnected_service';

const ClickmeServiceCard: TranslatedComponent<EmployerServiceWidget> = ({ trls, active, name }) => {
    const currency = useSelector((state) => state.employerIndexPage?.oldEmployer?.billingSummary?.currency);
    const clickmeBalance = useSelector((state) => state.employerIndexPage?.oldEmployer?.clickme?.realBalance);
    const clickmeCabinetsCount = useSelector((state) => state.employerIndexPage?.oldEmployer?.clickmeCabinetsCount);

    const handleClick = useHandleAnalyticsCardClick({ name, active });

    const getContent = () => {
        if (!clickmeCabinetsCount || clickmeCabinetsCount === 1) {
            if (currency && typeof clickmeBalance === 'number') {
                return <FormatMoney currency={currency}>{clickmeBalance}</FormatMoney>;
            }
            return 0;
        }

        return (
            <Conversion
                hasValue
                one={trls[TrlKeys.cabinets.one]}
                some={trls[TrlKeys.cabinets.many]}
                many={trls[TrlKeys.cabinets.some]}
                value={clickmeCabinetsCount}
            />
        );
    };

    const card = (
        <ServiceCard
            name={name}
            isActive={active}
            title={trls[active ? TrlKeys.title : TrlKeys.connectTitle]}
            icon={<ServiceCardIcon isActive={active} name="clickme" />}
        >
            {active ? (
                <Text typography="subtitle-1-semibold">{getContent()}</Text>
            ) : (
                <Link
                    target="_blank"
                    onClick={handleClick}
                    href={CLICKME_NOT_CONNECTED_LINK}
                    typography="label-2-regular"
                >
                    {trls[TrlKeys.connect]}
                </Link>
            )}
        </ServiceCard>
    );

    return active ? (
        <SPALink target="_blank" onClick={handleClick} to={CLICKME_CONNECTED_LINK}>
            {card}
        </SPALink>
    ) : (
        card
    );
};

export default translation(ClickmeServiceCard);
