import { FC, useRef } from 'react';

import { Avatar, Cell, Text, TooltipHover } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import VacanciesCounter from 'src/components/RainbowCatalog/VacanciesCounter';
import { getCompanyLink, sendAnalytics } from 'src/components/WorkInCompany/Utils';
import { useIsTextClamped } from 'src/hooks/useIsTextClamped';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { CompanyOfTheDay } from 'src/models/companiesOfTheDay';
import { WorkInCompanyLocation } from 'src/utils/sendAdvSpyAnalytics';

interface CompanyOfTheDayComponentProps {
    company: CompanyOfTheDay;
}

const CompanyOfTheDayComponent: FC<CompanyOfTheDayComponentProps> = ({ company }) => {
    const locale = useSelector((state) => state.locale);
    const isZP = useIsZarplataPlatform();

    const companyNameRef = useRef(null);
    const isCompanyNameClamped = useIsTextClamped(companyNameRef);

    return (
        <Cell
            align="top"
            key={company.companyName}
            avatar={
                <Avatar
                    shapeCircle
                    size={40}
                    mode="image"
                    fallbackMode="placeholder"
                    image={company.logoUrl || ''}
                    placeholder="city"
                    aria-label="company-logo"
                />
            }
        >
            <SPALink
                to={getCompanyLink(company, locale, WorkInCompanyLocation.RainbowCatalog, isZP)}
                onClick={() => sendAnalytics(company, 'rainbow_catalog')}
            >
                <Text typography="label-2-regular" style="primary" maxLines={1}>
                    {company.companyName}
                </Text>
            </SPALink>
            {isCompanyNameClamped && (
                <TooltipHover placement="top-center" activatorRef={companyNameRef}>
                    <div ref={companyNameRef}>{company.companyName}</div>
                </TooltipHover>
            )}
            <Text typography="label-3-regular" style="secondary">
                <VacanciesCounter value={company.vacanciesNumber} />
            </Text>
        </Cell>
    );
};
export default CompanyOfTheDayComponent;
