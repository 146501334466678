import { ReactNode, useLayoutEffect, useRef } from 'react';
import cn from 'classnames';

import { Layer, useCloseByClickOutside, useCollapsible, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import CollapseButton from 'src/components/EmployerBalanceWidget/CollapsibleContainer/CollapseButton';
import TopupButton from 'src/components/EmployerBalanceWidget/CollapsibleContainer/TopupButton';

import styles from './styles.less';

interface CollapsibleContainerProps {
    mainCard: ReactNode;
    children: ReactNode;
    isCollapsed: boolean;
    onCollapsedToggle: () => void;
    dropCardsCount: number;
    hasProblem: boolean;
    collapsedCards: ReactNode;
}

const TOP_SPACING = 16;

const CollapsibleContainer: TranslatedComponent<CollapsibleContainerProps> = ({
    children,
    mainCard,
    onCollapsedToggle,
    isCollapsed,
    collapsedCards,
    dropCardsCount,
    hasProblem,
}) => {
    const borderRef = useRef<HTMLDivElement>(null);
    const cardsRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const { collapsibleClasses } = useCollapsible(cardsRef, !isCollapsed);

    useCloseByClickOutside(containerRef, onCollapsedToggle, !isCollapsed);

    useLayoutEffect(() => {
        if (cardsRef.current && borderRef.current && containerRef.current) {
            const openedHeight = cardsRef.current.scrollHeight;
            const containerHeight = containerRef.current.clientHeight;
            borderRef.current.style.height = isCollapsed
                ? `${containerHeight}px`
                : `${containerHeight + openedHeight - TOP_SPACING}px`;
        }
    }, [isCollapsed]);

    return (
        <div className={styles.container}>
            <div ref={containerRef} className={styles.collapsibleContainer}>
                {mainCard}
                <VSpacing default={12} />
                {dropCardsCount > 0 ? (
                    <CollapseButton
                        hasProblem={hasProblem}
                        dropCardsCount={dropCardsCount}
                        isCollapsed={isCollapsed}
                        onCollapsedToggle={onCollapsedToggle}
                    />
                ) : (
                    <TopupButton />
                )}
                <div className={styles.collapsibleCardsContainer}>
                    <Layer layer="content">
                        <div ref={cardsRef} className={cn(styles.collapsibleCards, collapsibleClasses)}>
                            {children}
                        </div>
                    </Layer>
                </div>
                <Layer layer="content">
                    <div ref={borderRef} className={styles.border} />
                </Layer>
            </div>
            <div className={cn(styles.rowsContainer, { [styles.rowsContainerHidden]: !isCollapsed })}>
                {collapsedCards}
            </div>
        </div>
    );
};

export default translation(CollapsibleContainer);
