import { useEffect, useRef, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { useExperiment } from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import { VacanciesOfTheDay } from 'src/models/vacanciesOfTheDay';
import { fetcher } from 'src/utils/fetcher';

// tempexp_clickme-vacancy-of-the-day-experiment_start
import { EMPTY_ARRAY, MAX_VOD_VACANCIES_COUNT } from 'src/components/RainbowCatalog/const';
import getClickmeVacancies from 'src/components/RainbowCatalog/getClickmeVacancies';
import shuffleVacanciesOfTheDayWithClickme, {
    calcFirstFakeVacancyIndex,
} from 'src/components/RainbowCatalog/shuffleVacanciesOfTheDayWithClickme';
// tempexp_clickme-vacancy-of-the-day-experiment_end

const vacanciesOfTheDayAction = makeSetStoreField('vacanciesOfTheDay');

// tempexp_clickme-vacancy-of-the-day-experiment_start
const Features = {
    enableClickmeVacanciesShufflingInToVacanciesOfTheDayMainPage:
        'enable_clickme_vacancies_shuffling_in_to_vacancies_of_the_day_main_page',
};
// tempexp_clickme-vacancy-of-the-day-experiment_end

declare global {
    interface FetcherGetApi {
        '/shards/vacancies_of_the_day': {
            queryParams: null;
            response: Pick<VacanciesOfTheDay, 'vacancies' | 'compensation' | 'clickmeVacanciesPlacesList'>;
        };
    }
}

const useFetchVacanciesOfTheDay = (): void => {
    // tempexp_clickme-vacancy-of-the-day-experiment_start
    const dispatch = useDispatch();
    const isHH = useIsHhru();
    const isVacanciesOfTheDayFetchedRef = useRef(false);
    const vacanciesOfTheDay = useSelector((state) => state.vacanciesOfTheDay);
    // tempexp_clickme-vacancy-of-the-day-experiment_start
    const VODShufflingValue = useSelector(
        ({ features }) => features[Features.enableClickmeVacanciesShufflingInToVacanciesOfTheDayMainPage]
    ) as number;
    const isReplaceClickmeVacancyExp = useExperiment(
        'replace_clickme_vacancy_fixed',
        Boolean(vacanciesOfTheDay.clickmeVacancies.length)
    );
    // tempexp_clickme-vacancy-of-the-day-experiment_end

    useEffect(() => {
        if (isVacanciesOfTheDayFetchedRef.current) {
            return;
        }
        isVacanciesOfTheDayFetchedRef.current = true;
        dispatch(vacanciesOfTheDayAction({ ...vacanciesOfTheDay, isLoading: true }));

        void fetcher
            .get(`/shards/vacancies_of_the_day`)
            // tempexp_clickme-vacancy-of-the-day-experiment_start
            .then((data) => {
                const { vacancies: downloadedVacancies, ...downloadedVODData } = data;
                const firstFakeVacancyIndex = calcFirstFakeVacancyIndex(downloadedVacancies ?? EMPTY_ARRAY);
                const vacanciesCountToDownload = MAX_VOD_VACANCIES_COUNT - firstFakeVacancyIndex;
                const setVacanciesOfTheDayWithoutClickme = () =>
                    dispatch(
                        vacanciesOfTheDayAction({
                            ...vacanciesOfTheDay,
                            ...downloadedVODData,
                            vacancies: downloadedVacancies,
                            readyToShuffleVacancies: false,
                            isLoading: false,
                            success: true,
                        })
                    );

                if (downloadedVacancies && vacanciesCountToDownload && isHH) {
                    void getClickmeVacancies(
                        { ...downloadedVODData, vacancies: downloadedVacancies },
                        VODShufflingValue,
                        vacanciesCountToDownload
                    )
                        .then((clickmeVacancies) =>
                            dispatch(
                                vacanciesOfTheDayAction({
                                    ...vacanciesOfTheDay,
                                    ...downloadedVODData,
                                    tempVacancies: downloadedVacancies,
                                    clickmeVacancies,
                                    firstFakeVacancyIndex,
                                    readyToShuffleVacancies: true,
                                })
                            )
                        )
                        .catch(setVacanciesOfTheDayWithoutClickme);
                } else {
                    setVacanciesOfTheDayWithoutClickme();
                }
            })
            .catch(() => {
                dispatch(
                    vacanciesOfTheDayAction({
                        ...vacanciesOfTheDay,
                        readyToShuffleVacancies: false,
                        isLoading: false,
                        success: false,
                    })
                );
            });
    }, [VODShufflingValue, dispatch, isHH, isReplaceClickmeVacancyExp, vacanciesOfTheDay]);

    useLayoutEffect(() => {
        if (vacanciesOfTheDay.readyToShuffleVacancies) {
            dispatch(
                vacanciesOfTheDayAction({
                    ...vacanciesOfTheDay,
                    vacancies: shuffleVacanciesOfTheDayWithClickme(
                        vacanciesOfTheDay.tempVacancies,
                        vacanciesOfTheDay.clickmeVacancies,
                        vacanciesOfTheDay.firstFakeVacancyIndex,
                        isReplaceClickmeVacancyExp
                    ),
                    readyToShuffleVacancies: false,
                    isLoading: false,
                    success: true,
                })
            );
        }
    }, [dispatch, isReplaceClickmeVacancyExp, vacanciesOfTheDay]);
};
export { useFetchVacanciesOfTheDay };
