import { useMemo } from 'react';

import Analytics, { ElementShownAnchor } from '@hh.ru/analytics-js';
import { Text } from '@hh.ru/magritte-ui';
import { GlobeOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import BalanceCard from 'src/components/EmployerBalanceWidget/BalanceCard';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { formatNumber } from 'src/utils/formatNumber';

import ResumeAccessHoverContent from 'src/components/EmployerBalanceWidget/cards/ResumeAccessCard/ResumeAccessHoverContent';
import { isItemExpireSoon } from 'src/components/EmployerBalanceWidget/cards/ResumeAccessCard/isItemExpireSoon';
import { useGetPublicationItems } from 'src/components/EmployerBalanceWidget/cards/ResumeAccessCard/useGetResumeAccessItems';

const TrlKeys = {
    title: 'employer.index.balanceWidget.resumeAccess.title',
    empty: 'employer.index.balanceWidget.resumeAccess.empty',
    days: {
        one: 'index.employer.billing.days.one',
        some: 'index.employer.billing.days.some',
        many: 'index.employer.billing.days.many',
    },
};

const ResumeAccessCard: TranslatedComponent = ({ trls }) => {
    const employerIndexPage = useSelector(({ employerIndexPage }) => employerIndexPage);
    const isZp = useIsZarplataPlatform();
    const resumeAccessItems = useGetPublicationItems();

    const activeResumeAccessesInfo =
        employerIndexPage.oldEmployer?.billingSummary?.serviceCategories?.resumeAccessCodes || {};

    const isEmpty = !(activeResumeAccessesInfo.count || activeResumeAccessesInfo.remainingDays);
    const linkResumeAccessUrl = isZp ? paths.priceZpResumeAccess : paths.priceDBAccess;
    const isExpireSoon = useMemo(() => resumeAccessItems.some(isItemExpireSoon), [resumeAccessItems]);

    const analyticParams = {
        card: 'resume_access_balance',
        remainingDays: activeResumeAccessesInfo.remainingDays,
        resumes: activeResumeAccessesInfo.count || 0,
    };

    const handleClick = () => {
        const event = isEmpty ? 'balance_widget_card_plus_click' : 'balance_widget_card_click';

        Analytics.sendHHEventButtonClick(event, analyticParams);
    };

    const cardText = useMemo(() => {
        if (isEmpty) {
            return trls[TrlKeys.empty];
        } else if (
            typeof activeResumeAccessesInfo.remainingDays === 'number' &&
            activeResumeAccessesInfo.remainingDays > 0 &&
            isExpireSoon
        ) {
            return (
                <Conversion
                    hasValue
                    one={trls[TrlKeys.days.one]}
                    some={trls[TrlKeys.days.many]}
                    many={trls[TrlKeys.days.some]}
                    value={activeResumeAccessesInfo.remainingDays}
                />
            );
        }

        return formatNumber(
            resumeAccessItems.reduce((res, item) => {
                return res + (item.civBalance || 0);
            }, 0)
        );
    }, [activeResumeAccessesInfo.remainingDays, isEmpty, isExpireSoon, resumeAccessItems, trls]);

    const card = (
        <BalanceCard
            showBadge={isExpireSoon}
            addUrl={isEmpty ? linkResumeAccessUrl : undefined}
            type="default"
            rightContent={<GlobeOutlinedSize24 initialColor="secondary" />}
            title={trls[TrlKeys.title]}
            onPlusClick={handleClick}
            hoverContent={isEmpty ? null : <ResumeAccessHoverContent />}
        >
            <Text typography="subtitle-1-semibold">{cardText}</Text>
        </BalanceCard>
    );

    return (
        <ElementShownAnchor fn={Analytics.sendHHEventElementShown} name="balance_widget_card" {...analyticParams}>
            {isEmpty ? (
                card
            ) : (
                <SPALink onClick={handleClick} to={paths.accountHistoryServices}>
                    {card}
                </SPALink>
            )}
        </ElementShownAnchor>
    );
};

export default translation(ResumeAccessCard);
