import { forwardRef, ForwardRefRenderFunction, MutableRefObject } from 'react';

import { isExternalBannersRestricted } from 'HHC/Banners';
import { useIsBelarus } from 'src/hooks/useCountries';
import { useFeatureEnabled } from 'src/hooks/useFeatureEnabled';
import { AdsExternalBannerProps } from 'src/models/banners/AdsExternalBanner.types';

import Banner from 'src/components/Banner';

const Features = {
    enableADVWithoutCookieAccept: 'enable_adv_without_cookie_accept',
    yandexAdfoxStubEnabled: 'yandex_adfox_stub_enabled',
};

const AdsExternalBanner: ForwardRefRenderFunction<HTMLDivElement, AdsExternalBannerProps> = (
    { id, cId, pp, p2, ...adfoxParams },
    ref
) => {
    const isADVWithoutCookieAcceptEnabled = useFeatureEnabled(Features.enableADVWithoutCookieAccept);
    const isYandexAdfoxStubEnabled = useFeatureEnabled(Features.yandexAdfoxStubEnabled);
    const enableAdfox = pp && p2;
    const shouldHideExternalBanners = isExternalBannersRestricted(useIsBelarus());

    // Адфокс может быть включен, но на Беларуси не принята кука
    // Динамическая настройка isADVWithoutCookieAcceptEnabled одна на все страны
    // но учитывать мы её должны только для Беларуси
    // поэтому для всех стран баннеры не пустые, если выключен Адфокс
    // а для Беларуси ещё чекаем настройку
    if (!enableAdfox || shouldHideExternalBanners) {
        return (
            <Banner
                key={cId}
                id={id}
                ref={ref}
                isEmpty={shouldHideExternalBanners && !isADVWithoutCookieAcceptEnabled}
            />
        );
    }

    return (
        <Banner
            key={cId}
            id={id}
            templateName=""
            adfoxParams={{
                pp,
                p2,
                cId,
                shouldHideExternalBanners,
                useAdfoxMock: isYandexAdfoxStubEnabled,
                ...adfoxParams,
            }}
            render={(contentRef) => {
                return (
                    <div
                        ref={(element) => {
                            if (contentRef) {
                                contentRef.current = element;
                            }
                            if (ref && element) {
                                (ref as MutableRefObject<HTMLElement>).current = element;
                            }
                        }}
                        id={cId}
                        className={`HHC-Banner-${id} HH-Banner-Adfox banner-place banner-place_${id} banner-place-adfox banner-place-adfox_${id}`}
                        data-banner-id={id}
                        data-empty-class="banner-place_hide"
                        data-loaded-class="banner-place_show"
                    />
                );
            }}
        />
    );
};
export default forwardRef(AdsExternalBanner);
