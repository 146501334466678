import { useCallback, useMemo } from 'react';

import allVacanciesLinkClick from '@hh.ru/analytics-js-events/build/xhh/employer/vacancies_widget/vacancies_widget_all_vacancies_link_button_click';
import { useExperiment } from '@hh.ru/front-static-app';
import { Text, Button } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { getFooterLinkUrl } from 'src/components/EmployerVacanciesWidget/utils';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './footer.less';

const TrlKeys = {
    allVacanciesLink: 'employer.indexPage.widgets.myVacancies.allVacanciesLink',
    moreResponses: {
        responses: {
            one: 'employer.indexPage.widgets.myVacancies.moreResponses.responses.one',
            some: 'employer.indexPage.widgets.myVacancies.moreResponses.responses.some',
            many: 'employer.indexPage.widgets.myVacancies.moreResponses.responses.many',
        },
        vacancies: {
            one: 'employer.indexPage.widgets.myVacancies.moreResponses.vacancies.one',
            some: 'employer.indexPage.widgets.myVacancies.moreResponses.vacancies.some',
            many: 'employer.indexPage.widgets.myVacancies.moreResponses.vacancies.many',
        },
    },
};

const LINK_DATA_QA = 'my-vacancies_vacancies-link';

const Footer: TranslatedComponent = ({ trls }) => {
    const { moreResponses, activeVacanciesAmount, vacancies } = useSelector((state) => state.vacanciesWidget);
    const isMainPageRedesign = useExperiment('main_page_redesign_v1', true, false);
    const typography = isMainPageRedesign ? 'subtitle-2-semibold' : 'subtitle-1-semibold';

    const handleMoreVacanciesClick = useCallback(() => {
        allVacanciesLinkClick({ activeVacanciesAmount });
    }, [activeVacanciesAmount]);

    const { hasActive, hasArchive, hasDraft } = useMemo(
        () => ({
            hasActive: Boolean(vacancies.find(({ type }) => type === 'active')),
            hasDraft: Boolean(vacancies.find(({ type }) => type === 'draft')),
            hasArchive: Boolean(vacancies.find(({ type }) => type === 'archive')),
        }),
        [vacancies]
    );

    const link = getFooterLinkUrl({ hasActive, hasArchive, hasDraft });

    const renderContent = () => {
        if (moreResponses) {
            return (
                <Button
                    stretched
                    typography={typography}
                    Element={SPALink}
                    to={link}
                    data-qa={LINK_DATA_QA}
                    style="neutral"
                    mode="tertiary"
                    onClick={handleMoreVacanciesClick}
                >
                    <div className={styles.footerButtonContent}>
                        <Text typography={typography}>
                            <Conversion
                                value={moreResponses.responses}
                                one={trls[TrlKeys.moreResponses.responses.one]}
                                some={trls[TrlKeys.moreResponses.responses.some]}
                                many={trls[TrlKeys.moreResponses.responses.many]}
                                format={(trl) =>
                                    format(trl, {
                                        '{0}': moreResponses.responses,
                                    })
                                }
                                hasValue={false}
                            />
                        </Text>
                        <Text typography={typography} style="tertiary">
                            <Conversion
                                value={moreResponses.vacancies}
                                one={trls[TrlKeys.moreResponses.vacancies.one]}
                                some={trls[TrlKeys.moreResponses.vacancies.some]}
                                many={trls[TrlKeys.moreResponses.vacancies.many]}
                                format={(trl) =>
                                    format(trl, {
                                        '{0}': moreResponses.vacancies,
                                    })
                                }
                                hasValue={false}
                            />
                        </Text>
                    </div>
                </Button>
            );
        }

        return (
            <Button
                stretched
                typography={typography}
                Element={SPALink}
                to={link}
                data-qa={LINK_DATA_QA}
                style="neutral"
                mode="tertiary"
                onClick={handleMoreVacanciesClick}
            >
                <div className={styles.footerButtonContent}>
                    <Text typography={typography}>{trls[TrlKeys.allVacanciesLink]}</Text>

                    {hasActive && activeVacanciesAmount ? (
                        <Text typography={typography} style="tertiary" data-qa="my-vacancies_vacancies_amount">
                            {activeVacanciesAmount}
                        </Text>
                    ) : null}
                </div>
            </Button>
        );
    };

    return <div className={styles.footer}>{renderContent()}</div>;
};

export default translation(Footer);
