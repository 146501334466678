import { FC, ReactNode } from 'react';
import cn from 'classnames';

import Analytics, { ElementShownAnchor } from '@hh.ru/analytics-js';
import { Card, CardStyle, VSpacing, Text, Layer } from '@hh.ru/magritte-ui';

import { EmployerBannerName } from 'src/models/employerBannersWidget';

import styles from './styles.less';

export interface BannerCardProps {
    children: ReactNode;
    title?: ReactNode;
    name: EmployerBannerName;
    button: ReactNode;
    style: CardStyle;
    isTextSmall?: boolean;
    bannerHeight?: number;
}

const BannerCard: FC<BannerCardProps> = ({ name, button, title, isTextSmall, style, children, bannerHeight }) => {
    return (
        <ElementShownAnchor
            className={styles.anchor}
            fn={Analytics.sendHHEventElementShown}
            name="banners_widget_card"
            card={name}
        >
            <div className={styles.cardContainer}>
                <Card verticalStretched stretched borderRadius={24} padding={12} style={style}>
                    <Layer layer="content">
                        <div
                            className={styles.container}
                            style={bannerHeight ? { height: `${bannerHeight}px` } : undefined}
                        >
                            <div className={styles.content}>
                                {title && (
                                    <>
                                        {title}
                                        <VSpacing default={12} />
                                    </>
                                )}
                                <Text typography={isTextSmall ? 'label-4-regular' : 'label-2-regular'}>{children}</Text>
                            </div>
                            {button}
                        </div>
                    </Layer>
                    <div className={cn(styles.image, styles[name])}></div>
                </Card>
            </div>
        </ElementShownAnchor>
    );
};

export default BannerCard;
