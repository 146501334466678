import { useCallback, useState } from 'react';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { useHandleAnalyticsCardClick } from 'src/components/EmployerBannersWidget/useHandleAnalyticsCardClick';
import { useNotification } from 'src/components/Notifications/Provider';
import { EmployerBannerName } from 'src/models/employerBannersWidget';
import { fetcher } from 'src/utils/fetcher';

const MAKE_SEEN_URL = '/shards/employer/banners_widget_make_seen';

declare global {
    interface FetcherPutApi {
        [MAKE_SEEN_URL]: {
            response: void;
            queryParams: void;
            body: {
                banner: EmployerBannerName;
            };
        };
    }
}

export const useHandleSeenClick = (name?: EmployerBannerName) => {
    const [isSent, setIsSent] = useState(false);
    const { addNotification } = useNotification();
    const sendAnalytics = useHandleAnalyticsCardClick();

    return useCallback(async () => {
        if (isSent || !name) {
            return;
        }
        sendAnalytics(name);
        try {
            setIsSent(true);
            await fetcher.put(MAKE_SEEN_URL, { banner: name });
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
        }
    }, [addNotification, isSent, name, sendAnalytics]);
};
