import Analytics, { ElementShownAnchor } from '@hh.ru/analytics-js';
import { Text } from '@hh.ru/magritte-ui';
import { TextStyle } from '@hh.ru/magritte-ui-typography/types';
import { ArrowRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import routePaths from 'src/app/routePaths';
import BalanceCard from 'src/components/EmployerBalanceWidget/BalanceCard';
import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { CurrencyType } from 'src/models/currencies.types';
import { PfpBalanceWarning } from 'src/models/payForPerformance/pfpBalanceWarning';

const TrlKeys = {
    title: 'employer.index.balanceWidget.pfpBalance.title',
};

interface PfpBalanceCardProps {
    pfpBalanceWarning: PfpBalanceWarning | null;
}

const PfpBalanceCard: TranslatedComponent<PfpBalanceCardProps> = ({ trls, pfpBalanceWarning }) => {
    const employerIndexPage = useSelector((state) => state.employerIndexPage);
    const balance = employerIndexPage.oldEmployer?.pfpInfo?.pfpDetails?.balance?.value;

    const isEmpty = typeof balance !== 'number' || balance === 0;

    const analyticParams = {
        card: 'pfp_balance',
        hasMoney: !!balance,
    };

    const handleClick = () => {
        const event = isEmpty ? 'balance_widget_card_plus_click' : 'balance_widget_card_click';

        Analytics.sendHHEventButtonClick(event, analyticParams);
    };

    let style: TextStyle = 'primary';

    if (pfpBalanceWarning === PfpBalanceWarning.LowBalance) {
        style = 'warning';
    } else if (pfpBalanceWarning === PfpBalanceWarning.ZeroBalance) {
        style = 'negative';
    }

    const card = (
        <BalanceCard
            addUrl={isEmpty ? routePaths.pricePayForPerformance : undefined}
            type="drop"
            rightContent={<ArrowRightOutlinedSize24 initialColor="secondary" />}
            title={trls[TrlKeys.title]}
            onPlusClick={handleClick}
        >
            <Text style={style} typography="subtitle-1-semibold">
                <FormatMoney currency={CurrencyType.RUR}>{balance || 0}</FormatMoney>
            </Text>
        </BalanceCard>
    );

    return (
        <ElementShownAnchor fn={Analytics.sendHHEventElementShown} name="balance_widget_card" {...analyticParams}>
            {isEmpty ? (
                card
            ) : (
                <SPALink onClick={handleClick} to={routePaths.accountHistory}>
                    {card}
                </SPALink>
            )}
        </ElementShownAnchor>
    );
};

export default translation(PfpBalanceCard);
