import Analytics from '@hh.ru/analytics-js';
import debounce from 'bloko/common/debounce';
import urlParser from 'bloko/common/urlParser';

import Components from 'HHC/Components';

const ADFOX_SEPARATOR = ':';

const noopData = (d) => d;

const HB_ADDITIONAL_FIELDS = {
    domainAreaId: noopData,
    contextRegions: noopData,
    profileRegions: noopData,
    regions: noopData,
    rId: String,
};

const createAdListenersWithTimeout = () => {
    window.Ya ??= {};

    if (!window.Ya._adListeners) {
        window.Ya._adListeners = {
            hbCallbacks: {
                data: [],
                done: debounce(() => {
                    window.Ya?.adfoxCode?.hbCallbacks?.push(() =>
                        window.Ya?.headerBidding?.pushAdUnits(
                            window.Ya._adListeners.hbCallbacks.data.flatMap((d) => d())
                        )
                    );
                    window.Ya._adListeners.hbCallbacks.data.length = 0;
                }, 200),
                push: (d) => {
                    window.Ya._adListeners.hbCallbacks.data.push(d);
                    window.Ya._adListeners.hbCallbacks.done();
                },
            },
            yaContextCb: {
                data: [],
                done: debounce(() => {
                    window.Ya._adListeners.yaContextCb.data.forEach((d) => window.yaContextCb.push(d));
                    window.Ya._adListeners.yaContextCb.data.length = 0;
                }, 200),
                push: (d) => {
                    window.Ya._adListeners.yaContextCb.data.push(d);
                    window.Ya._adListeners.yaContextCb.done();
                },
            },
        };
    }
};

const addYandexBanner = (element, params, isRetry = false) => {
    const date = new Date();
    const requestBannersUrl = urlParser(window.globalVars.bannersBatchUrl);
    const logicRegions = requestBannersUrl.params?.regions
        ? requestBannersUrl.params.regions[0].split(',').join(ADFOX_SEPARATOR) || ''
        : '';

    const mergedRegions = params.bannerCommonTargeting.puid1
        ? `${logicRegions}${ADFOX_SEPARATOR}${params.bannerCommonTargeting.puid1}`
        : logicRegions;

    const hideContainer = () => (element.style.display = 'none');
    const isHeaderBidding = Boolean(params.headerBidding);

    createAdListenersWithTimeout();

    if (isHeaderBidding) {
        window.YaHeaderBiddingSettings = {
            biddersMap: params.headerBidding.biddersMap,
            timeout: 2000,
        };
        window.Ya?._adListeners?.hbCallbacks?.push(() => {
            const additional = Object.entries(HB_ADDITIONAL_FIELDS).reduce(
                (result, [hbField, processor]) =>
                    Object.assign(result, { [hbField]: processor(requestBannersUrl.params[hbField]) }),
                {}
            );

            return [
                {
                    code: params.cId,
                    sizes: [params.headerBidding.sizes],
                    bids: Object.keys(params.headerBidding.biddersMap).map((bidder) => ({
                        bidder,
                        params: {
                            placementId: params.place,
                            codeType: 'code',
                            additional: { additional },
                        },
                    })),
                },
            ];
        });
    }

    window.Ya._adListeners.yaContextCb.push(() => {
        window.Ya.adfoxCode.create({
            ownerId: params.ownerId,
            sequentialLoading: true,
            containerId: params.cId,
            params: {
                // Описание параметров https://wiki.hh.ru/display/ADV/DFP.+Key+Values
                ps: params.ps,
                pp: params.pp,
                p2: params.p2,
                puid4: date.getDay(),
                puid5: date.getHours(),
                puid6: window.globalVars.userType,
                puid7: window.location.host,
                puid9: window.globalVars.ads_sandbox,
                puid18: params.place,
                puid20: params.positionInfo,
                puid39: !params.shouldHideExternalBanners,
                ...params.bannerCommonTargeting,
                ...params.bannerAdTargeting,
                puid1: mergedRegions,
            },
            lazyLoad: {
                fetchMargin: 200,
                mobileScaling: 2,
            },
            onLoad: (data) => {
                if (data) {
                    element.dataset.adfoxRequested = 'true';
                }
            },
            onRender: (data) => {
                if (data) {
                    params.callbacks.success();
                }
            },
            onStub: () => {
                params.callbacks.error();
                element.dataset.adfoxRequested = 'true';
                hideContainer();
            },
            onError: (error) => {
                params.callbacks.error();
                element.dataset.adfoxRequested = 'true';
                Analytics.sendHHEvent('adfox_on_error', {
                    bannerId: params.bannerId,
                    pathname: window.location.pathname,
                    isRetry,
                    error: JSON.stringify(error),
                });
                if (!isRetry) {
                    addYandexBanner(element, params, true);
                } else {
                    hideContainer();
                }
            },
        });
    });
};

export default Components.build({
    create(element, params) {
        window.yaContextCb = window.yaContextCb || [];
        addYandexBanner(element, params);
    },
});
