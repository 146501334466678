import { FC, useEffect, useRef } from 'react';
import classNames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { useExperiment } from '@hh.ru/front-static-app';
import { Card } from '@hh.ru/magritte-ui';

import { useSelector } from 'src/hooks/useSelector';

import EmptySearches from 'src/components/IndexPageEmployer/components/LastSearches/EmptySearches';
import SearchList from 'src/components/IndexPageEmployer/components/LastSearches/SearchList';

interface LastSearchesProps {
    maxHeight?: number;
    maxItemsCount?: number;
}

const LastSearches: FC<LastSearchesProps> = ({ maxHeight, maxItemsCount }) => {
    const ref = useRef(null);
    const lastSearches = useSelector((state) => state.employerLastSearches);
    const hasItems = lastSearches.length > 0;
    const isMainPageRedesign = useExperiment('main_page_redesign_v1', true, false);

    useEffect(() => {
        if (ref.current) {
            const { stopSpying } = Analytics.sendHHEventElementShown(ref.current, {
                name: 'last_searches',
                type: lastSearches.length ? 'searches' : 'empty',
            });

            return stopSpying;
        }

        return undefined;
    }, [lastSearches.length]);

    return (
        <div
            ref={ref}
            className={classNames('last-searches-wrapper', {
                'last-searches-wrapper_not-empty': hasItems,
            })}
            style={hasItems && maxHeight ? { maxHeight: `${maxHeight}px` } : undefined}
        >
            <Card
                borderWidth="default"
                padding={isMainPageRedesign && !lastSearches.length ? 12 : 24}
                paddingBottom={isMainPageRedesign ? 12 : undefined}
                borderRadius={24}
                stretched
                verticalStretched
            >
                {lastSearches.length ? (
                    <SearchList searches={maxItemsCount ? lastSearches.slice(0, maxItemsCount) : lastSearches} />
                ) : (
                    <EmptySearches />
                )}
            </Card>
        </div>
    );
};

export default LastSearches;
