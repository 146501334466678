import Analytics, { ElementShownAnchor } from '@hh.ru/analytics-js';
import { Text } from '@hh.ru/magritte-ui';
import { ArrowRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import routePaths from 'src/app/routePaths';
import BalanceCard from 'src/components/EmployerBalanceWidget/BalanceCard';
import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    title: 'employer.index.balanceWidget.clickme.title',
    cabinets: {
        one: 'employer.index.balanceWidget.clickme.cabinet.one',
        some: 'employer.index.balanceWidget.clickme.cabinet.some',
        many: 'employer.index.balanceWidget.clickme.cabinet.many',
    },
};

const ClickmeBalanceCard: TranslatedComponent = ({ trls }) => {
    const employerIndexPage = useSelector(({ employerIndexPage }) => employerIndexPage);
    const currency = employerIndexPage?.oldEmployer?.billingSummary?.currency;

    const clickmeBalance = employerIndexPage?.oldEmployer?.clickme?.realBalance;
    const clickmeCabinetsCount = employerIndexPage?.oldEmployer?.clickmeCabinetsCount;

    const isEmpty = !clickmeBalance || clickmeBalance === 0;

    const analyticParams = {
        card: 'clickme_balance',
        hasMoney: !!clickmeBalance,
        cabinets: clickmeCabinetsCount,
    };

    const handleClick = () => {
        const event = isEmpty ? 'balance_widget_card_plus_click' : 'balance_widget_card_click';

        Analytics.sendHHEventButtonClick(event, analyticParams);
    };

    const getContent = () => {
        if (!clickmeCabinetsCount || clickmeCabinetsCount === 1) {
            return currency && typeof clickmeBalance === 'number' ? (
                <FormatMoney currency={currency}>{clickmeBalance}</FormatMoney>
            ) : (
                0
            );
        }

        return (
            <Conversion
                hasValue
                one={trls[TrlKeys.cabinets.one]}
                some={trls[TrlKeys.cabinets.many]}
                many={trls[TrlKeys.cabinets.some]}
                value={clickmeCabinetsCount}
            />
        );
    };

    const card = (
        <BalanceCard
            addUrl={isEmpty ? routePaths.priceClickmeProducts : undefined}
            type="drop"
            rightContent={<ArrowRightOutlinedSize24 initialColor="secondary" />}
            onPlusClick={handleClick}
            title={trls[TrlKeys.title]}
        >
            <Text typography="subtitle-1-semibold">{getContent()}</Text>
        </BalanceCard>
    );

    return (
        <ElementShownAnchor fn={Analytics.sendHHEventElementShown} name="balance_widget_card" {...analyticParams}>
            {isEmpty ? (
                card
            ) : (
                <SPALink onClick={handleClick} to={routePaths.accountHistory}>
                    {card}
                </SPALink>
            )}
        </ElementShownAnchor>
    );
};

export default translation(ClickmeBalanceCard);
