import { MutableRefObject } from 'react';

import { Button, Text, VSpacing } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Footer from 'src/components/EmployerResumeSearchesWidget/components/Footer';
import Placeholder from 'src/components/EmployerResumeSearchesWidget/components/Placeholder';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import SavedSearchesItem from 'src/components/EmployerResumeSearchesWidget/components/SavedSearches/SavedSearchesItem';
import { useHandleAnalytics } from 'src/components/EmployerResumeSearchesWidget/components/SavedSearches/useHandleAnalytics';

const TrlKeys = {
    allSearches: 'resumeSearchesWidget.savedSearches.allSearches',
    placeholder: {
        title: 'resumeSearchesWidget.savedSearches.placeholder.title',
        description: 'resumeSearchesWidget.savedSearches.placeholder.description',
        button: 'resumeSearchesWidget.savedSearches.placeholder.button',
    },
};

interface SavedSearchesProps {
    containerRef: MutableRefObject<HTMLDivElement | null>;
}

const SavedSearches: TranslatedComponent<SavedSearchesProps> = ({ trls, containerRef }) => {
    const {
        savedSearches: { items },
    } = useSelector((state) => state.resumeSearchesWidget);

    const { handleAllItemsClick, handleLinkClick, allItemsButtonRef } = useHandleAnalytics(containerRef);

    if (!items.length) {
        return (
            <Placeholder>
                <Text typography="title-5-semibold">{trls[TrlKeys.placeholder.title]}</Text>
                <VSpacing default={8} />
                <Text typography="paragraph-2-regular" style="secondary">
                    {trls[TrlKeys.placeholder.description]}
                </Text>
                <VSpacing default={32} />
                <Button
                    stretched
                    typography="subtitle-1-semibold"
                    Element={SPALink}
                    to="/search/resume"
                    mode="primary"
                    style="accent"
                >
                    {trls[TrlKeys.placeholder.button]}
                </Button>
            </Placeholder>
        );
    }

    return (
        <>
            {items.map((item) => (
                <SavedSearchesItem key={item.id} data={item} onClick={handleLinkClick} />
            ))}

            <Footer>
                <Button
                    stretched
                    typography="subtitle-1-semibold"
                    Element={SPALink}
                    to="/resumesavedsearch"
                    style="neutral"
                    mode="tertiary"
                    onClick={handleAllItemsClick}
                    ref={allItemsButtonRef}
                >
                    {trls[TrlKeys.allSearches]}
                </Button>
            </Footer>
        </>
    );
};

export default translation(SavedSearches);
