import { Cell, CellText, Text } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Area from 'src/components/EmployerResumeSearchesWidget/components/CellSubtitle/Area';
import Salary from 'src/components/EmployerResumeSearchesWidget/components/CellSubtitle/Salary';
import Subtitle, { SubtitleItem } from 'src/components/EmployerResumeSearchesWidget/components/CellSubtitle/Subtitle';
import CellWrapper from 'src/components/EmployerResumeSearchesWidget/components/CellWrapper';
import translation from 'src/components/translation';
import { ResumeSavedSearchItem } from 'src/models/resumeSavedSearch';
import { CriteriaKey } from 'src/types/search/common/criteria';
import { deleteDeprecatedFilter } from 'src/utils/resumeSearch/deleteDeprecatedFilter';

interface SavedSearchesItemProps {
    data: ResumeSavedSearchItem;
    onClick: () => void;
    isShort?: boolean;
}

const TrlKeys = {
    noName: 'resumeSearchesWidget.savedSearches.noName',
};

const SavedSearchesItem: TranslatedComponent<SavedSearchesItemProps> = ({ data, trls, onClick, isShort }) => {
    const { name, searchAllQuery } = data;

    const getCellSubtitle = (savedSearch: ResumeSavedSearchItem): SubtitleItem[] => {
        const { searchCriteria, params } = savedSearch;
        const areas = params[CriteriaKey.Area];
        const salaryFrom = searchCriteria[CriteriaKey.SalaryFrom];
        const salaryTo = searchCriteria[CriteriaKey.SalaryTo];

        const subtitleItems: SubtitleItem[] = [{ content: <Area areas={areas} /> }];

        if (salaryFrom || salaryTo) {
            subtitleItems.push({
                growing: true,
                content: (
                    <Salary
                        salaryFrom={salaryFrom}
                        salaryTo={salaryTo}
                        currencyType={searchCriteria[CriteriaKey.CurrencyCode]}
                    />
                ),
            });
        }

        return subtitleItems;
    };
    return (
        <CellWrapper isShort={isShort}>
            <SPALink
                to={`/search/resume?${deleteDeprecatedFilter(searchAllQuery)}&L_is_autosearch=true`}
                onClick={onClick}
            >
                {isShort ? (
                    <>
                        <Text maxLines={1}>{name || trls[TrlKeys.noName]}</Text>
                        <Subtitle inCell={false} items={getCellSubtitle(data)} />
                    </>
                ) : (
                    <Cell right={<ChevronRightOutlinedSize24 />}>
                        <CellText maxLines={1}>{name || trls[TrlKeys.noName]}</CellText>
                        <Subtitle items={getCellSubtitle(data)} />
                    </Cell>
                )}
            </SPALink>
        </CellWrapper>
    );
};

export default translation(SavedSearchesItem);
