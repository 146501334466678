import { useRef } from 'react';

import { Button, Card, Divider, Text, VSpacing } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import Footer from 'src/components/EmployerResumeSearchesWidget/components/Footer';
import SavedSearchesItem from 'src/components/EmployerResumeSearchesWidget/components/SavedSearches/SavedSearchesItem';
import { useHandleAnalytics } from 'src/components/EmployerResumeSearchesWidget/components/SavedSearches/useHandleAnalytics';

import styles from './styles.less';

const MAX_COUNT = 3;

const TrlKeys = {
    title: 'resumeSearchesWidget.tab.savedSearches',
    allSavedSearches: 'resumeSearchesWidget.savedSearches.allSavedSearches',
    placeholder: {
        title: 'resumeSearchesWidget.savedSearches.placeholder.title',
        description: 'resumeSearchesWidget.savedSearches.placeholder.description',
        button: 'resumeSearchesWidget.savedSearches.placeholder.button',
    },
};

const SavedSearchesWidget: TranslatedComponent = ({ trls }) => {
    const {
        savedSearches: { items },
    } = useSelector((state) => state.resumeSearchesWidget);
    const contentRef = useRef<HTMLDivElement | null>(null);

    const { handleAllItemsClick, handleLinkClick, allItemsButtonRef } = useHandleAnalytics(contentRef);

    const buttonTypography = 'subtitle-2-semibold';

    let content = null;

    if (!items.length) {
        content = (
            <Card verticalStretched padding={12}>
                <div className={styles.savedSearchesPlaceholderContainer}>
                    <div className={styles.savedSearchesPlaceholderContent}>
                        <Text typography="title-5-semibold">{trls[TrlKeys.placeholder.title]}</Text>
                        <VSpacing default={12} />
                        <Text typography="paragraph-2-regular" style="secondary">
                            {trls[TrlKeys.placeholder.description]}
                        </Text>
                    </div>
                    <Button
                        stretched
                        typography="subtitle-1-semibold"
                        Element={SPALink}
                        to="/search/resume"
                        mode="secondary"
                    >
                        {trls[TrlKeys.placeholder.button]}
                    </Button>
                </div>
            </Card>
        );
    } else {
        content = (
            <>
                <div className={styles.savedSearchesTitle}>
                    <Text typography="title-5-semibold" data-qa="saved-searches-title">
                        {trls[TrlKeys.title]}
                    </Text>
                </div>
                <Divider />
                <div className={styles.savedSearchesContent} ref={contentRef}>
                    {items.slice(0, MAX_COUNT).map((item) => (
                        <SavedSearchesItem isShort key={item.id} data={item} onClick={handleLinkClick} />
                    ))}
                </div>
                <Divider />
                <Footer>
                    <Button
                        stretched
                        typography={buttonTypography}
                        Element={SPALink}
                        to="/resumesavedsearch"
                        style="neutral"
                        mode="tertiary"
                        size="small"
                        onClick={handleAllItemsClick}
                        ref={allItemsButtonRef}
                    >
                        <div className={styles.savedSearchesButtonContent}>
                            <Text typography={buttonTypography}>{trls[TrlKeys.allSavedSearches]}</Text>
                            <Text typography={buttonTypography} style="tertiary">
                                {items.length}
                            </Text>
                        </div>
                    </Button>
                </Footer>
            </>
        );
    }

    return (
        <Card verticalStretched stretched borderWidth="default" borderRadius={24}>
            <div className={styles.savedSearchesContainer}>{content}</div>
        </Card>
    );
};

export default translation(SavedSearchesWidget);
