import { ReactNode, useState, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import vacancyCatalogItemButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/main/vacancy_catalog_item_button_click';
import { ChipsContainer, CustomChip, BottomSheet, NavigationBar, Action } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Strings from 'Utils/Strings';
import paths from 'src/app/routePaths';
import BottomSheetProfessions from 'src/components/RainbowCatalog/BottomSheet';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { openAnonymousSignUpModal } from 'src/models/anonymousSignUp';
import { useMainPageSignupEnable } from 'src/models/anonymousSignUp/hooks';
import { ProfessionsListProfession } from 'src/models/professionsList';

const TrlKeys = {
    promoRemote: 'professions.promo.remote',
    more: {
        title: 'professions.more',
        one: 'professions.one',
        some: 'professions.some',
        many: 'professions.many',
        suitable: 'anonymousSuitableVacancies.showMore',
    },
    vacancies: {
        one: 'professions.vacancies.one',
        some: 'professions.vacancies.some',
        many: 'professions.vacancies.many',
    },
};

const ChipsProfessions: TranslatedComponent = ({ trls }) => {
    const professionsList = useSelector((state) => state.professionsList);
    const userType = useSelector((state) => state.userType);
    const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false);
    const remoteWorkCounter = useSelector((state) => state.remoteWorkCounter);
    const dispatch = useDispatch();
    const [activeMobileTileContent, setActiveMobileTileContent] = useState<{
        title: string;
        body: ReactNode;
        vacanciesCount: number | undefined;
    } | null>(null);
    const isMainPageSignupEnable = useMainPageSignupEnable();

    const remotePromoTile = {
        title: trls[TrlKeys.promoRemote],
        vacanciesCount: remoteWorkCounter.count,
        link: `${paths.vacancySearch}?schedule=remote&L_profession_id=0&area=${remoteWorkCounter.areaId}`,
        dataQa: { tile: 'remote-item-mobile' },
    };

    const handleSendAnalytics = (title: string, chipsIndex: number) => {
        Analytics.sendEvent(userType, 'profession_block', title);
        vacancyCatalogItemButtonClick({ value: title, position: chipsIndex });
    };

    const handlePromoChipsClick = (
        event: MouseEvent<HTMLAnchorElement | HTMLDivElement>,
        title: string,
        link: string,
        chipsIndex: number
    ) => {
        if (isMainPageSignupEnable) {
            event.preventDefault();
            dispatch(openAnonymousSignUpModal(link));
        }
        handleSendAnalytics(title, chipsIndex);
    };

    const handleChipsClick = (profession: ProfessionsListProfession, chipsIndex: number) => {
        handleSendAnalytics(profession.name, chipsIndex);
        if (professionsList) {
            setIsBottomSheetVisible(true);
            setActiveMobileTileContent({
                title: profession.name,
                body: (
                    <BottomSheetProfessions
                        professions={profession.professions}
                        parentProfession={profession}
                        currency={professionsList?.currency}
                    />
                ),
                vacanciesCount: profession.count,
            });
        }
    };

    return (
        <div>
            <ChipsContainer scrollable>
                <CustomChip
                    Element="a"
                    href={remotePromoTile.link}
                    onClick={(e) => {
                        handlePromoChipsClick(e, remotePromoTile.title, remotePromoTile.link, 1);
                    }}
                    key={remotePromoTile.title}
                >
                    <span data-qa={remotePromoTile.dataQa.tile}>{remotePromoTile.title}</span>
                </CustomChip>

                {professionsList?.professions?.map((profession, index) => {
                    return (
                        <CustomChip
                            Element="button"
                            onClick={() => {
                                handleChipsClick(profession, index + 2);
                            }}
                            key={profession.name}
                        >
                            {profession.name}
                        </CustomChip>
                    );
                })}
            </ChipsContainer>
            <BottomSheet
                visible={isBottomSheetVisible}
                onClose={() => setIsBottomSheetVisible(false)}
                header={
                    <NavigationBar
                        right={
                            <Action
                                icon={CrossOutlinedSize24}
                                onClick={() => {
                                    setIsBottomSheetVisible(false);
                                }}
                            />
                        }
                        title={activeMobileTileContent?.title}
                        subtitle={
                            activeMobileTileContent?.vacanciesCount
                                ? Strings.numConversion(activeMobileTileContent.vacanciesCount, [
                                      trls[TrlKeys.vacancies.one],
                                      trls[TrlKeys.vacancies.some],
                                      trls[TrlKeys.vacancies.many],
                                  ])
                                : undefined
                        }
                        showDivider="always"
                    />
                }
            >
                <div>{activeMobileTileContent?.body}</div>
            </BottomSheet>
        </div>
    );
};

export default translation(ChipsProfessions);
