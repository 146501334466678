import { FC, ReactNode, useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';

import { CellText, Text, useBreakpoint } from '@hh.ru/magritte-ui';

import styles from './styles.less';

const SECTION_MIN_WIDTH = 150;

export interface SubtitleItem {
    content: ReactNode;
    growing?: boolean;
}

interface SubtitleProps {
    items: SubtitleItem[];
    inCell?: boolean;
}

const Subtitle: FC<SubtitleProps> = ({ items, inCell = true }) => {
    const sectionsRef = useRef<HTMLDivElement[]>([]);
    const { isXS } = useBreakpoint();

    const handleRef = (node: HTMLDivElement | null, item: SubtitleItem) => {
        if (!node || item.growing) {
            return;
        }

        sectionsRef.current.push(node);
    };

    useLayoutEffect(() => {
        if (isXS) {
            return;
        }

        sectionsRef.current.forEach((section) => {
            const textContent = section.firstElementChild;

            if (textContent && textContent.clientWidth < SECTION_MIN_WIDTH) {
                section.style.minWidth = `${textContent.clientWidth}px`;
            }
        });
    }, [isXS]);

    const content = (
        <div className={styles.subtitle}>
            {items.map((item, index) => {
                return (
                    <div
                        key={index}
                        className={classNames(styles.section, { [styles.growing]: item.growing })}
                        ref={(node) => {
                            handleRef(node, item);
                        }}
                    >
                        <Text typography="label-3-regular" style="secondary" maxLines={1}>
                            {item.content}
                        </Text>
                    </div>
                );
            })}
        </div>
    );

    if (inCell) {
        return <CellText>{content}</CellText>;
    }

    return content;
};

export default Subtitle;
