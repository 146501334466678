import { useSelector } from 'src/hooks/useSelector';
import { publicationsCodes, ServiceItemCode } from 'src/models/price/productCodes';

const useGetPublicationItems = () => {
    const employerIndexPage = useSelector(({ employerIndexPage }) => employerIndexPage);

    if (employerIndexPage.oldEmployer) {
        const { serviceDescription } = employerIndexPage.oldEmployer.employer;

        return serviceDescription.filter(
            (service) => publicationsCodes.includes(service.code) && service.code !== ServiceItemCode.ZpDemo
        );
    }

    return [];
};

export { useGetPublicationItems };
